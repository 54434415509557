import { ConfigProvider, Flex, Row, Upload, UploadProps } from "antd"
import styles from "./ExhibitUpload.module.scss"
import Icon from "../../components/icon/Icon"
import { CSSProperties, PropsWithChildren, ReactNode, createContext, useContext, useEffect, useState } from "react"
import { FormContext, FormItemContext } from "../../components/form/FormContext"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import Env from "../../../Env"
import uploadSvg from "../assets/images/upload-cloud-02.svg"
import { DraggerProps } from "antd/es/upload"
import axios from "axios"
import fileSvg from "../assets/images/file-04.svg"
import trashSvg from "../assets/images/trash-01.svg"
import { format } from "../../libs/bytes"
import checkSvg from "../assets/images/check.svg"
import fileUploadedSvg from "../assets/images/file-04-uploaded.svg"
import { toHumanDateTime } from "../../libs/datetime"
import OssApi from "../../api/Oss.api"


interface UploadingFile{
    precent: number
    name: string
    size: string
    status: "done"|"uploading"|""
    updated_at?: string
    contentType?: string
}

interface UploadedFile{
    name?: string
    size?: number
    updated_at?: string
    contentType?: string
    id: string
}


export default (props: {readonly?: boolean, extraRender?: ReactNode, onFileAdd: (fileId: string) => void, onFileDelete: (fileId: string) => void}) => {

    const {
        Dragger
    } = Upload

    const {
        readonly,
        extraRender,
        onFileAdd,
        onFileDelete
    } = props

    const {client} = useContext(ClientCaseContext)
    const {name} = useContext(FormItemContext)
    const {values, setValues} = useContext(FormContext)
    const [fileId, setFileId] = useState<string>()
    const [uploadingFile, setUploadingFile] = useState<UploadingFile>()
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])

    // useEffect(() => {
    //     setValues(name, uploadedFiles)
    // }, [uploadedFiles])

    useEffect(() => {
        setUploadedFiles(values[name] as any)
    }, [values])
    

    const customRequest: DraggerProps['customRequest'] = ({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials
        }) => {

            const _file = file as File
            const name = _file.name
            const type = _file.type

            setUploadingFile({
                name: _file.name,
                precent: 0,
                size: format(_file.size),
                status: "",
                updated_at: new Date().toISOString(),
                contentType: _file.type,
            })

            return OssApi.getUploadToken(client?.client_id as string, {
                name, 
                type,
                metadata: {},
                size: _file.size,
                content_type: type
            }).then(token => {
                Env.DEBUG && console.log("getToken: ", token)
                const {
                    file_id,
                    upload_args
                } = token
                const {
                url,
                fields
                } = upload_args
                
                setFileId(file_id)
                const formData = new FormData()
                Object.entries(fields).forEach(([key, value]) => {
                    formData.append(key, value)
                })
                formData.append("file", _file);
                
                const config= {
                    "headers": {
                        "content-type": 'multipart/form-data;'
                    },
                }
                return axios.post(url, formData, {...config, onUploadProgress: (e) => onProgress?.({...e})}).then((res: any) => {
                    Env.DEBUG && console.log("uploadSuccess: ", res)
                    onSuccess?.(file_id)
                }).catch((err: Error) => {
                    console.error(err)
                    onError?.(err)
                })

            })
    }

    const onChange: DraggerProps['onChange'] = (uploadFile) => {
        Env.DEBUG && console.log("onChange", uploadFile)
        if(uploadFile.event){
            const percent = Math.floor(parseFloat((uploadFile.event as any)?.progress??0) * 100)
            setUploadingFile({
                name: uploadFile.file.name as string,
                precent: Math.min(percent, 99),
                size: format(uploadFile.file.size??0),
                status: "uploading",
                updated_at: uploadingFile?.updated_at,
                contentType: uploadingFile?.contentType
            })
        }
        if(uploadFile.file.status == "done"){
            Env.DEBUG && console.log("onUploaded, fileId: ", fileId)
            setUploadedFiles(currentFiles => {
                if(!currentFiles) currentFiles = []
                currentFiles =  [
                    ...currentFiles,
                    {
                        "id": fileId as string,
                        "name": uploadFile.file.name,
                        "size": uploadFile.file.size,
                        "updated_at":  uploadingFile?.updated_at,
                        "contentType": uploadingFile?.contentType
                    }
                ]
                setValues(name, currentFiles)
                return currentFiles;
            })
            onFileAdd(fileId as string)
            setUploadingFile(undefined)
        }
    }

    const onDelete = (doc: UploadedFile) => {
        // setValues(name, undefined)
        const currentFiles = uploadedFiles.filter(it => it.id !== doc.id)
        setUploadedFiles(currentFiles)
        setValues(name, currentFiles)
        onFileDelete(doc.id)
    }

    const draggerRender = () => {
        return <ConfigProvider
                theme={{
                    token:{
                        colorFillAlter: "#fff",
                        padding: 0,
                    }
                }}
            >
                <Dragger 
                    style={{border: 0}}
                    multiple={true}
                    customRequest={customRequest}
                    onChange={onChange}
                    showUploadList={false}
                >
                    <Flex vertical gap={12} align="center" className={styles['document_upload__dragger']}>
                        <Icon src={uploadSvg} size={40} padding={0}></Icon>
                        <Flex vertical gap={4} align="center">
                            <Flex gap={4} align="center"> 
                                <a className={styles['document_upload__dragger__a']}>Click to upload</a>
                                or drag and drop
                            </Flex>
                            <div className={styles['document_upload__dragger__tips']}>PDF or DOC (max. 800mb)</div>
                        </Flex>
                        {extraRender}
                    </Flex>
                </Dragger>
            </ConfigProvider>

    }

    const uploadingRender = () => {
        return (
            <Flex gap={16} align="center" className={styles["document-upload__uploading"]}>
                <Icon src={fileSvg} size={28}></Icon>
                <Flex vertical gap={4} style={{flex: 1}}>
                    <Flex vertical>
                        <Flex justify="space-between" align="center">
                            <div className={styles['document-upload__uploading__filename']}>{uploadingFile?.name}</div>
                            <Icon src={uploadingFile?.status !== "done" ? trashSvg: checkSvg} size={20} ></Icon>
                        </Flex>
                        {/* <div className={styles['document-upload__uploading__filesize']}>{uploadingFile?.size}</div> */}
                    </Flex>
                    <Progress percent={uploadingFile?.precent??0}/>
                </Flex>
            </Flex>
        )        
    }

    const uploadedRender = () => {

        return (
            <>
                {
                    uploadedFiles && uploadedFiles.map(doc => {
                        return (
                            <Flex gap={16} align="center" className={[styles["document-upload__uploaded"]].join(" ")}>
                                <Icon src={fileUploadedSvg} size={28}></Icon>
                                <Flex gap={16} style={{flex: 1}}>
                                    <Flex align="center" justify="space-between" style={{flex: 1}}>
                                        <Flex vertical>
                                            <div className={[styles['document-upload__uploaded__filename']].join(" ")}>{doc.name}</div>
                                            <div className={[styles['document-upload__uploaded__filesize']].join(" ")}>{format(doc.size??0)}</div>
                                        </Flex>
                                        <div className={[styles['document-upload__uploaded__updatedAt']].join(" ")}>Last update: {toHumanDateTime(doc.updated_at as string)}</div>
                                    </Flex>
                                    <Icon src={trashSvg} size={20} padding={8} hoverable onClick={() => onDelete(doc)}></Icon>
                                </Flex>
                            </Flex>
                        )
                    })
                }
            </>
        )

    }
    

    return (
        <Flex vertical gap={12}>
            <div className={[styles['document-upload'], (values[name]||uploadingFile)?styles['document-upload_fill']:''].join(" ")}>
                {
                    draggerRender()
                }
            </div>
            {
                uploadingFile &&
                uploadingRender()
            }
            {
                uploadedRender()
            }

        </Flex>
    )
    
}

const Progress = (props: {percent: number}) => {

    const style:CSSProperties = {
        maxWidth: `calc(100% * ${props.percent * 0.01})`
    }

    return (
        <Flex gap={12} className="progress" align="center">
            <div className={styles["progress__bar"]}>
                <div 
                    className={styles["progress__bar__scroller"]}
                    style={style}
                ></div>
            </div>
            <div className={styles["progress__label"]}>{props.percent}%</div>
        </Flex>
    )
}