

import { CSSProperties, PropsWithChildren } from "react"
import styles from "./Swiper.module.scss"


export const Swiper = (props: PropsWithChildren<{currentIdx: number}>) => {

    const {
        currentIdx
    } = props

    const getStyle = (): CSSProperties => {

        return {
            transform: `translateX(-${currentIdx * 100}%)`,
        }

    }

    return (
        <div className={styles['swiper']}>
            <div className={styles['swiper__container']} style={getStyle()}>
                {props.children}
            </div>
        </div>
    )

}


export const SwiperItem = (props: PropsWithChildren<{idx: number}>) => {

    const {
        idx,
        children
    } = props

    const getStyle = (): CSSProperties => {

        return {
            left: `${idx * 100}%`,
            top: 0
        }

    }

    return (
        <div className={styles['swiper__item']} style={getStyle()}>
            {children}
        </div>
    )

}