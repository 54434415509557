
import { Flex } from "antd"
import firstUploadPng from "../assets/images/first_upload_guideline.png"
import Icon from "../../components/icon/Icon"
import Button from "../../components/Button"
import styles from "./FirstUploadGuideline.module.scss"
import { useContext } from "react"
import { UserContext } from "../../login/context/UserContext"
import loginApi from "../../api/login.api"

export default (props: {onConfirm?: () => void, onCancel?: () => void}) => {

    const {
        onConfirm,
        onCancel
    } = props

    const {user, changeTourStatus} = useContext(UserContext)

    const gotItHandle = () => {
        loginApi.updateTourStauts("PETITION_LETTER_UPDATE").then(() => {
            changeTourStatus("PETITION_LETTER_UPDATE", true)
        })
        onCancel && onCancel()
    }

    const tryItNowHandle = () => {
        loginApi.updateTourStauts("PETITION_LETTER_UPDATE").then(() => {
            changeTourStatus("PETITION_LETTER_UPDATE", true)
        })
        onConfirm && onConfirm()
    }

    return (
        <Flex vertical className={styles['guideline']} gap={8} align="center">
            <img src={firstUploadPng} className={styles['guideline__img']}></img>
            <label className={styles['guideline__label']}>You have new evidence updates. Click 'Update’ to refresh your AI petition letter result.</label>
            <Flex style={{width: "100%"}} justify="flex-end" gap={12}>
                <Button.TransparentBg onClick={gotItHandle}>Got it</Button.TransparentBg>
                <Button.Primary onClick={tryItNowHandle}>Try it now</Button.Primary>
            </Flex>
        </Flex>
    )
}