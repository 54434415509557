import { Flex } from "antd"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import FormValidators from "../../components/form/FormValidators"
import { StepForm } from "../CaseFillingForm"
import { useContext, useEffect } from "react"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import CaseFillingApi from "../../api/CaseFilling.api"
import Env from "../../../Env"
import { useNavigate } from "react-router-dom"

const categories = [
    "EB1A",
]

const CategoriesOptions = categories.map(it => {return {label: it, value: it}})

export default () => {

    return (
        <StepForm
            title="Select application category"
            subtitle="To proceed, please choose the type of visa your client is applying for from the options provided."
            required
        >
            <SelectApplicationCategory/>
        </StepForm>
    )
}

const SelectApplicationCategory = () => {

    const formInstance = useFormInstance({autoValidate: false})
    const {client, clientCase} = useContext(ClientCaseContext)
    const {record, update, setStep, isFinish, setDisable} = useContext(CaseFillingFormContext)
    const navigate = useNavigate()

    useEffect(() => {
        formInstance.setInitial({'decision': 'EB1A'})
        if(!record?.field_application || record?.field_application === ""){
            setDisable(CaseFilingSteps.FieldOfApplication, true)
        }
    }, [])

    const doSubmit = async (form: any) => {
        const clientId = client?.client_id as string
        const caseId = clientCase?.case_id as string
        const taskId = record?.id as string
        return CaseFillingApi.updateApplicationCategory(caseId, taskId, form).then(async () => {
            navigate(`/workbench/clientmanagement/${clientId}/${caseId}/0/${taskId}`)
            // CaseFillingApi.updateStatus(caseId, taskId, "FIELD_OF_APPLICATION")
            // update(await CaseFillingApi.detail(caseId, taskId))
            // setStep(CaseFilingSteps.AcademicBackground)
            // setDisable(CaseFilingSteps.FieldOfApplication, undefined)
            //TODO chishijie

        })
    }


    return (
        <Form.Form instance={formInstance} showError={false} onSubmit={doSubmit}>
            <Form.Item 
                label="Immigration Type" 
                name="decision" 
                required 
                rules={[{validate: FormValidators.required, msg: "Immigration Type is required"}]}
            >
                <Form.Select placeholder="Select one" options={CategoriesOptions}/>
            </Form.Item>
            <Form.Item nowrap label="" name="">
                <Flex justify="flex-end">
                    <Form.Submit width={185}>Continue</Form.Submit>
                </Flex>
            </Form.Item>
        </Form.Form>
    )
}

