


import { Checkbox, Col, ConfigProvider, Flex, Row, Upload, UploadProps } from "antd"
import styles from "./CaseFillingForm.module.scss"
import Icon from "../components/icon/Icon"
import logOutSvg from "./assets/images/log-out-04.svg"
import { CSSProperties, PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import checkCircleSvg from "./assets/images/check-circle.svg"
import alertCircleSvg from "./assets/images/alert-circle.svg"
import alertTriangleSvg from "./assets/images/alert-triangle.svg"
import wizardAlertTriangleSvg from "./assets/images/wizard-alert-triangle.svg"
import infoCircleSvg from "./assets/images/info-circle.svg"

import chevronUpSvg from "./assets/images/chevron-up.svg"
import chevronDownSvg from "./assets/images/chevron-down.svg"
import Env from "../../Env"
import { CaseFilingSteps, CaseFillingFormContext, convertToStep, getActivateStep, useCaseFillingFormContext } from "./context/CaseFillingFormContext"
import ProtectPage from "../components/layout/ProtectPage"
import EssentialSteps from "./modules/EssentialSteps"
import UploadResume from "./modules/UploadResume"
import FieldOfApplication from "./modules/FieldOfApplication"
import AcademicBackground from "./modules/AcademicBackground"
import Publications from "./modules/Publications"
import Awards from "./modules/Awards"
import PublicationReviews from "./modules/PublicationReviews"
import MediaCoverage from "./modules/MediaCoverage"
import MemberShip from "./modules/MemberShip"
import ArtExhibition from "./modules/ArtExhibition"
import JobAndTitle from "./modules/JobAndTitle"
import ReviewAndSubmit from "./modules/ReviewAndSubmit"
import GenerateEvidence from "./modules/Recommenders"
import CaseFillingApi, { CaseFilingTaskDTO } from "../api/CaseFilling.api"
import { useClientCaseContext } from "../clientmanagement/context/ClientCaseContext"
import { BroadcastMessage, BroadcastMessageTextNode, CaseFilingNotificationContext, NotificationMessageEventName, useCaseFilingNotificationContext } from "./context/CaseFilingNotificationContext"
import xCloseSvg from "./assets/images/x-close.svg"
import Patents from "./modules/Patents"
import LoadingIcon from "../components/icon/LoadingIcon"
import SelectApplicationCategory from "./modules/SelectApplicationCategory"

export default (props: {onClose?: (forceRefresh?: boolean) => void, onChange?: () => void, task?: CaseFilingTaskDTO}) => {

    const context = useCaseFillingFormContext()
    const clientCaseContext = useClientCaseContext()
    const notificationContext = useCaseFilingNotificationContext()
    const [loading, setLoading] = useState<boolean>(() => props.task?true:false)
    
    const {
        step,
        setStep,
        init,
        record
    } = context

    const {
        task
    } = props

    useEffect(() => {
        Env.DEBUG &&  console.log("task: ", task)
        if(task){
            CaseFillingApi.detail(task.case_id, task.id).then((record) => {
                init(record);
                setStep(getActivateStep(record))
                setLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        record && props.onChange?.()
    }, [record])

    const chooseContent = () => {
        switch(step){
            case CaseFilingSteps.UploadResume:
                return <UploadResume/>
            case CaseFilingSteps.FieldOfApplication:
                return <FieldOfApplication />
            case CaseFilingSteps.AcademicBackground:
                return <AcademicBackground />
            case CaseFilingSteps.Publications:
                return <Publications/>
            case CaseFilingSteps.Awards:
                return <Awards/>
            case CaseFilingSteps.PublicationReviews:
                return <PublicationReviews/>
            case CaseFilingSteps.Patents:
                return <Patents/>
            case CaseFilingSteps.MediaCoverage:
                return <MediaCoverage/>
            case CaseFilingSteps.MemberShip:
                return <MemberShip/>
            case CaseFilingSteps.ArtExhibition:
                return <ArtExhibition/>
            case CaseFilingSteps.JobAndTitle:
                return <JobAndTitle/>
            case CaseFilingSteps.ReviewAndSubmit:
                return <ReviewAndSubmit/>
            case CaseFilingSteps.GenerateEvidence:
                return <SelectApplicationCategory/>
            default:
                return <EssentialSteps/>
        }
    }
    
    return (
        <CaseFilingNotificationContext.Provider value={notificationContext}>
            <CaseFillingFormContext.Provider value={context}>
                <ProtectPage className={styles["form"]}>
                    <Flex vertical style={{height: "100%"}}>
                        <Flex gap={32} align="center" className={styles["form__header"]}>
                            <Icon size={24} src={logOutSvg} onClick={() => props.onClose?.()}></Icon>
                            Case Filing
                        </Flex>       
                        <Flex style={{flex: 1, minHeight: 1}}>
                            {
                                loading
                                ?
                                <div style={{padding: 28}}><LoadingIcon/></div>
                                :
                                (
                                    step == 0
                                    ?
                                    <Flex justify="center" className={styles['form__guide']}>
                                        <EssentialSteps/>
                                    </Flex>
                                    :
                                    <>
                                        <div className={styles["form__wizard"]}>
                                            <Wizard/>
                                        </div>
                                        <div className={styles["form__content"]} style={{flex: 1}}>
                                            {<NotificationBar/>}
                                            {chooseContent()}
                                        </div>
                                    </> 
                                ) 
                                
                            }
                        </Flex>     
                    </Flex>
                </ProtectPage>
            </CaseFillingFormContext.Provider>
        </CaseFilingNotificationContext.Provider>
    )
}

const Wizard = () => {

    const {step, setStep} = useContext(CaseFillingFormContext)

    const disabled = (current: number) => {
        throw new Error("Not implemented")
        //return current > convertToStep(data?.status)
    }

    useEffect(() => {
        // setStep(1)
    }, [])

    return (
        <div className={styles['wizard']}>
            <div className={styles['wizard__title']}>Case Progress</div>
            <div className={styles['wizard__scrollview']}>
                <WizardGroup title="Evidence Analysis">
                    <WizardList>
                        <WizardItemDropDown title="Collect information">
                            <WizardList sub>
                                <WizardItem title="Upload resume" required sub step={1}/>
                                <WizardItem title="Field of application" required sub step={2}/>
                                <WizardItem title="Academic background" required sub step={3}/>
                                <WizardItem title="Publications" sub step={4}/>
                                <WizardItem title="Awards" sub step={5}/>
                                <WizardItem title="Publication reviews" sub step={6}/>
                                <WizardItem title="Patents" sub step={7}/>
                                <WizardItem title="Media coverage" sub step={8}/>
                                <WizardItem title="Membership" sub step={9}/>
                                <WizardItem title="Art exhibition" sub step={10}/>
                                <WizardItem title="Job and title" sub step={11}/>
                                <WizardItem title="Review and submit" sub step={12}/>
                            </WizardList>
                        </WizardItemDropDown>
                    </WizardList>
                </WizardGroup>
                <WizardGroup title="Case Type Decision">
                    <WizardList>
                    {/* <WizardItem title="Generate evidence" step={13} /> */}
                        <WizardItem title="Select application category" step={13}/>
                    </WizardList>
                </WizardGroup>
                <WizardGroup title="Recommendation Letter">
                    <WizardList>
                        <WizardItem title="Select recommender" step={15}/>
                        <WizardItem title="Generate letter" step={16}/>
                        <WizardItem title="Upload signed letter" step={17}/>
                    </WizardList>
                </WizardGroup>
                <WizardGroup title="Petition Letter">
                    <WizardList>
                        <WizardItem title="Review evidence" step={18}/>
                        <WizardItem title="Generate letter" step={19}/>
                    </WizardList>
                </WizardGroup>
            </div>
            
        </div>
    )

}


const WizardGroup = (props: PropsWithChildren<{title: string}>) => {
    return (
        <>
            <div className={styles['wizard__group']}>
                {props.title}
            </div>
            {props.children}
        </>
    )
}

const WizardList = (props: PropsWithChildren<{sub?: boolean}>) => {
    return (
        <Flex 
            vertical 
            gap={4} 
            className={
                [
                    styles['wizard__list'],
                    props.sub?styles['wizard__list_sub']:''
                ].join(" ")
            }
        >
            {props.children}
        </Flex>
    )
}

const WizardItem = (props: PropsWithChildren<{title: string, required?: boolean, sub?: boolean, step: number}>) => {
    
    const {step, setStep, isActivate, isFinish, isDisable: isValid} = useContext(CaseFillingFormContext)

    const {
        sub,
    } = props

    const iconRender = () => {
        if(!isValid(props.step)) return <Icon src={wizardAlertTriangleSvg} size={20}></Icon>
        if(isFinish(props.step)) return <Icon src={checkCircleSvg} size={20}></Icon>
        return <></>
    }

    return (
        <Flex 
            align="center"
            className={[
                styles['wizard__list__item'], 
                sub?styles['wizard__list__item_sub']:'',
                step == props.step?styles['wizard__list__item_activate']:'',
                isFinish(props.step)?styles['wizard__list__item_finish']:'',
                !isActivate(props.step)?styles['wizard__list__item_disabled']:''
            ].join(" ")}
            onClick={() => isActivate(props.step) && setStep(props.step)}
            >
            {props.title}
            <div className={styles['wizard__list__item__icon']}>
                {
                    iconRender()
                }
            </div>
        </Flex>
    )
}

const WizardItemDropDown = (props: PropsWithChildren<{title: string, show?: boolean}>) => {

    const [show, setShow] = useState<boolean>(props.show??true) 
    
    return (
        <>
            <Flex 
                align="center"
                className={[
                    styles['wizard__list__item'], 
                    false?styles['wizard__list__item_activate']:'',
                    false?styles['wizard__list__item_finish']:'',
                    false?styles['wizard__list__item_disabled']:''
                ].join(" ")} onClick={() => setShow(!show)}>
                {props.title}
                <div className={styles['wizard__list__item__icon']} >
                    {
                        <Icon src={chevronUpSvg} size={20} className={
                            [
                                styles["wizard__list__item__icon_nodropdown"],
                                show?styles["wizard__list__item__icon_dropdown"]:''
                            ].join(" ")
                        }
                            ></Icon>
                    }
                </div>
            </Flex>
            <div className={
                    [
                        styles['wizard__dropdown'],
                        show?styles['wizard__dropdown_show']:''
                    ].join(" ")
                }>
                {props.children}
            </div>
            
        </>
    )
}

export const StepForm = (props: PropsWithChildren<{title: string, subtitle: string, required?: boolean}>) => {
    
    const {step} = useContext(CaseFillingFormContext)

    const getHeaderLabel = () => {
        switch(step){
            case CaseFilingSteps.UploadResume:
            case CaseFilingSteps.FieldOfApplication:
            case CaseFilingSteps.AcademicBackground:
            case CaseFilingSteps.Publications:
            case CaseFilingSteps.Awards:
            case CaseFilingSteps.PublicationReviews:
            case CaseFilingSteps.Patents:
            case CaseFilingSteps.MediaCoverage:
            case CaseFilingSteps.MemberShip:
            case CaseFilingSteps.ArtExhibition:
            case CaseFilingSteps.JobAndTitle:
            case CaseFilingSteps.ReviewAndSubmit:
                return "Evidence Analysis"
            case CaseFilingSteps.GenerateEvidence:
                return "Case Type Decision"
            default:
                return "Guide to Case Filing"
        }
    }

    return (
        <Flex vertical gap={32} className={styles['stepform']}>
            <Flex vertical gap={24} className={styles['stepform__header']}>
                {getHeaderLabel()}
                <Flex vertical gap={4}>
                    <Flex className={styles["stepform__title"]} align="center">
                        {props.title}
                        {/* {props.required?<div className={styles['stepform__title__required']}>*</div>:<></>} */}
                    </Flex>
                    <div className={styles["stepform__subtitle"]}>{props.subtitle}</div>
                </Flex>
            </Flex>
            <div className={styles['stepform__body']}>
                {props.children}
            </div>
        </Flex>
    )
}



const NotificationBar = () => {

    const context = useContext(CaseFilingNotificationContext)
    const [show, setShow] = useState<boolean>(false)
    const [message, setMessage] = useState<BroadcastMessage>()

    useEffect(() => {

        if(context.broadcastMessage){
            context.cleanBroadcastMessage()
            const remove = context.broadcastMessage.type === "clean"
            if(!remove){
                setMessage(context.broadcastMessage)
            }else{
                setMessage(undefined)
            }
            
        }

    }, [context.broadcastMessage])

    const clean = () => {
        setMessage(undefined)
        setShow(false)
    }

    const contentRender = () => {

        if(!message){
            return  <></>
        }

        const getIcon = () => {
            switch(message.type){
                case "warn":
                    return alertTriangleSvg
                case "success":
                    return checkCircleSvg
                case "fail":
                    return alertCircleSvg
                default:
                    return infoCircleSvg
            }
        }

        const textRender = () => {

            if(typeof message.text === 'string'){
                return message.text
            }else{
                return (message.text as BroadcastMessageTextNode)()
            }

        }

        return (
            <Flex align="center" justify="space-between" className={[styles['notificationbar'], styles[`notificationbar_${message.type}`]].join(' ')}>
                <Flex gap={12} align="center">
                    <Icon src={getIcon()} size={20}></Icon>
                    {textRender()}
                </Flex>
                <Icon src={xCloseSvg} size={20} hoverable onClick={clean}></Icon>
            </Flex>
        )
    }


    return (
        <div className={[styles['notificationbar__wrapper'], show?styles['notificationbar__wrapper_hiddien']:''].join(" ")}>
            {contentRender()}
        </div>
    )

}