
import { useEffect, useRef, useState } from "react";
import styles from "./Tabs.module.scss";
import { Flex } from "antd";
import { useSpring, animated } from '@react-spring/web'
import Env from "../../../Env";


export interface TabsProps{
    items: string[]
    onChange: (idx: number) => void
    init?: number
}

export default (props: TabsProps) => {

    const currentRef = useRef<HTMLDivElement>(null)
    const [activateIdx, setActivateIdx] = useState<number>(0)
    const [springs, api] = useSpring(() => ({
        from: { x: 0, width: 0 },
      }))


    const activate = (idx: number) => {
        setActivateIdx(idx)
    }

    useEffect(() => {

        if(props.init !== undefined){
            setActivateIdx(props.init)
        }

    }, [props.init])

    useEffect(() => {
        
        if(currentRef && currentRef.current){
            const items = Array.from(currentRef.current.querySelectorAll("div[data-role='tabItems']"))
            const firstItem = items[0]
            const item = items[activateIdx]
            const width = item.getBoundingClientRect().width
            const to = item.getBoundingClientRect().left - firstItem.getBoundingClientRect().left
            Env.DEBUG && console.log("to :", to, "width: ", width, "item.getBoundingClientRect().left: ", item.getBoundingClientRect().left, " firstItem.getBoundingClientRect().left: ", firstItem.getBoundingClientRect().left)
            api.start({
                to: {x: to, width},
                config:{
                    duration: 150
                }
            })
    
            props.onChange(activateIdx)
        }

    }, [activateIdx, currentRef])

    return (
        <div className={styles['tabs']} ref={currentRef}>
            <Flex gap={16}>
                {props.items.map((item, idx) => (
                    <div 
                        data-role="tabItems"
                        className={[styles['tabs__item'], activateIdx == idx?styles['tabs__item_activate']:''].join(" ")} 
                        onClick={() => activate(idx)}
                    >
                        {item}
                    </div>
                ))}
            </Flex>
            <div className={styles['tabs__highlight']}>
                <animated.div className={styles['tabs__highlight__bar']} style={springs}></animated.div>
            </div>
        </div>
    )
}