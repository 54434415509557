import { FormItemValidateRule } from "./FormContext"



const required: FormItemValidateRule['validate'] = (value: any): Promise<boolean> => {
  const result = value && value !== ""
  const arrayResult = !(value instanceof Array) || value.length > 0
  return Promise.resolve(result && arrayResult)
}

const email: FormItemValidateRule['validate'] = (value: any): Promise<boolean> => {
    const result = !!String(value).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return Promise.resolve(result)
}

const password: FormItemValidateRule['validate'] = (value: any): Promise<boolean> => {
    // const result = !!String(value).toLowerCase().match(
    //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g
    // );
    return Promise.resolve(value && (value as string).length >= 8)
}

export default {
    required,
    email,
    password
}