

export interface HttpResponse<T>{
    code: "200" | string
    data: T
    message: string
    status: string
}

export const checkCode = <T>(response: HttpResponse<T>) => {
    if(response.code !== "200") throw response
    
}