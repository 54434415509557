import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"


export interface CaseFilingCreateRequest{
    file_id: string
    google_scholar_link: string
}

export type CaseFilingTaskStatus = "CASE_CREATED" | "RESUME_UPLOADED" | "ANALYSING_EVIDENCE"
export type EvidenceStatus = "UNSPECIFIED" | "SUBMITTED" | "FAILED" | "SUCCEEDED"
export type ResumeExtractionStatus = EvidenceStatus
export type SourceType = "RESUME" | "GOOGLE_SCHOLAR_LINK"

export interface CaseFilingTaskDTO{
    case_id: string
    case_name: string
    case_status: CaseFilingTaskStatus
    created_at: string
    evidence_status: EvidenceStatus
    id: string
    resume_extraction_status: ResumeExtractionStatus
    publication_extraction_status: ResumeExtractionStatus
    updated_at: string
    info_source: InfoSource[]
    field_application?: string
    collect_info_status: Record<CaseFilingStatus, boolean>
    case_type_decision: string
}

export type InfoSource = ScholarInfoSource | ResumeInfoSource

export interface ScholarInfoSource{
    case_task_id: string
    created_at: string
    file_id: string
    id: string
    source_data: string
    source_meta_data: string
    source_type: SourceType
    updated_at: string
}

export interface ResumeInfoSource{
    case_task_id: string
    created_at: string
    file_id: string
    id: string
    source_data: string
    source_meta_data: string
    source_type: SourceType
    updated_at: string
    file_size: number
    file_name: string
}

export type CaseFilingStatus = 
    "UPLOAD_RESUME" | 
    "FIELD_OF_APPLICATION" | 
    "ACADEMIC_BACKGROUND" |
    "PUBLICATIONS" |
    "AWARDS" |
    "PUBLICATION_REVIEW" |
    "PATENTS" |
    "MEDIA_COVERAGE" |
    "MEMBERSHIP" |
    "ART_EXHIBITION" |
    "JOB_AND_TITLE" | 
    "REVIEW_SUBMIT"


export interface StrengthTagDTO{
    case_filing_task_id: string
    created_at: string
    id: string
    strength_keyword: string
    strength_type: StrengthType
    updated_at: string
    user_id: string
    strengths: StrengthTagDetail[]
}
export interface StrengthTagDetail{
    id: string
    statement: string
    evidence: StrengthTagDetailEvidence[]
}
export interface StrengthTagDetailEvidence{
    description: string
    level: number
    sources: StrengthTagDetailEvidenceSource[]
    title: string
}
export interface StrengthTagDetailEvidenceSource{
    url: string
    url_text: string
}

export type StrengthType = "review" | "award" | "edu" | "art" | "patent" | "publication"
export type RecommenderType = "DEPENDENT" | "INDEPENDENT" | "OTHER"
export interface RecommenderDTO{
    case_filing_task_id: string
    created_at: string
    employer: string
    id: string
    letter_task_status: string
    name: string
    publications: RecommenderPublicationDTO[]
    recommender_type: RecommenderType
    updated_at: string
    user_id: string
}

export interface RecommenderPublicationDTO{
    created_at: string
    id: string
    publish_name: string
    publish_time: string
    publisher: string
    recommender_id: string
    updated_at: string
    user_id: string
}

const list = async (case_id: string, props: any = {}) => {

    const res = (await axios.get(
        `/api/cases/${case_id}/case_filing`
    )).data as HttpResponse<CaseFilingTaskDTO[]>
    checkCode(res)
    return res.data

}

const create = async (case_id: string, request: CaseFilingCreateRequest) => {

    const res = (await axios.post(
        `/api/cases/${case_id}/case_filing`,
        request
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const update = async (case_id: string, taskId: string, request: CaseFilingCreateRequest) => {

    const res = (await axios.patch(
        `/api/cases/${case_id}/case_filing/${taskId}`,
        request
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const detail = async (case_id: string, task_id: string) => {

    const res = (await axios.get(
        `/api/cases/${case_id}/case_filing/${task_id}`
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data as CaseFilingTaskDTO
}

const updateFieldOfApplication = async (case_id: string, task_id: string, request: any) => {

    const res = (await axios.patch(
        `/api/cases/${case_id}/case_filing/${task_id}/field`,
        request
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const updateStatus = async (caseId: string, taskId: string, status: CaseFilingStatus, flag = true) => {
    const res = (await axios.patch(
        `/api/cases/${caseId}/case_filing/${taskId}/info_status`,
        {
            "info_status_type": status,
            "status": flag
        }
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const evidence = async (caseId: string, taskId: string) => {
    const res = (await axios.post(
        `/api/cases/${caseId}/case_filing/${taskId}/evidence`,
        {}
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const tags = async (caseId: string, taskId: string) => {
    const res = (await axios.get(
        `/api/cases/${caseId}/case_filing/${taskId}/tags`,
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data as StrengthTagDTO[]
}

const recommends = async (caseId: string, taskId: string) => {
    const res = (await axios.get(
        `/api/cases/${caseId}/case_filing/${taskId}/recommenders`,
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data as RecommenderDTO[]
}

const updateApplicationCategory = async (case_id: string, task_id: string, form: any) => {
    const res = (await axios.patch(
        `/api/cases/${case_id}/case_filing/${task_id}/decision`,
        form
    )).data as HttpResponse<any>
    // checkCode(res)
    return res.data
}

const decision = async (case_id: string, task_id: string, decision: string) => {
    const res = (await axios.patch(
        `/api/cases/${case_id}/case_filing/${task_id}/decision`,
        {decision}
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const duplicate = async (case_id: string, task_id: string) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/case_filing/${task_id}/duplicate`,
        {decision}
    )).data as HttpResponse<any>
    // checkCode(res)
    return res.data

}

export default{
    list,
    create,
    update,
    detail,
    updateFieldOfApplication,
    updateStatus,
    evidence,
    tags,
    recommenders: recommends,
    updateApplicationCategory,
    decision,
    duplicate
}