import { Flex } from "antd"
import Button from "../../components/Button"

import googleLogoSvg from "../assets/images/google-logo.svg"
import Icon from "../../components/icon/Icon"
import { GoogleLogin } from "@react-oauth/google"
import useToken from "../../libs/useToken"
import { login } from "../../api/login.api"
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"
import useMessage from "../../components/useMessage"
import Env from "../../../Env"
import { SignupContext } from "../../signup/context/SignupContext"
import { useContext } from "react"

export default () => {

    const {setToken} = useToken()
    const navigate = useNavigate()
    const {render: messageRender, error, api} = useMessage()
    const {setRegister, register} = useContext(SignupContext)

    const responseMessage = (response: any) => {
      api.loading({content: "Logging in", duration: 0, style: {"minWidth": "30rem"}})
        login({ google_token: response.credential }).then((result) => {

          Env.DEBUG && console.log("google登录成功, result: ", result);

          switch (result.code) {
            case "200":
              api.destroy()
              setToken("token");
              Cookies.set("google_token", response.credential);
              navigate("/workbench/chatbot");
              break;
            case "10000":
              const { next_step, signup_token } = result.data as any;
              setRegister({
                email: "",
                signup_token
              })
              switch(next_step){
                case "tell_us_more":
                    navigate("/signup/tell_us_more")
                    break;
                case "verify_email":
                    navigate("/signup/verify_email")
                    break;
              }
              break;
            case "10005":
              navigate(`/addedToQueue`);
              break;
            default:
              api.destroy()
              api.error({content: result.message, duration: 3})
          }
        });
      };
    
      const errorMessage = () => {
        error("Google login exception")
      };

    const loginBtn = (
        <>
            {messageRender}
            <GoogleLogin
                onSuccess={responseMessage}
                onError={errorMessage}
                width={"360"}
            />
        </>
        // <Button.WhiteBg>
        //     <Flex gap={12} align="center" justify="center">
        //         <Icon src={googleLogoSvg} size={24}></Icon>
        //         Log in with Google
        //     </Flex>
        //     {messageRender}
        // </Button.WhiteBg>
    )

    return {
        loginBtn
    }
    

}