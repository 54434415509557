import { Flex, message } from "antd"
import Screen from "../components/Screen"

import styles from "./Login.module.scss";
import logoSvg from "./assets/images/Logo.svg";
import useGoogleLogin from "./components/useGoogleLogin";
import Divider from "./components/Divider";
import Form from "../components/form/Form";
import { useContext, useState } from "react";
import Link from "./components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import loginApi from "../api/login.api";
import Env from "../../Env";
import useToken from "../libs/useToken";
import { useNavigate } from "react-router-dom";
import { useFormInstance } from "../components/form/FormContext";
import { RegisterParams, SignupContext } from "../signup/context/SignupContext";

const {
    required,
    email
} = validators

export default () => {

    const {loginBtn: googleLoginBtn} = useGoogleLogin()
    const [loginStep, setLoginStep] = useState<number>(1)
    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const {setToken} = useToken()
    const navigate = useNavigate()
    const formInstance = useFormInstance()
    const {register, setRegister} = useContext(SignupContext)

    const doSubmit = (params: any) => {
        if(loading) return;
        setLoading(true)
        messageApi.loading({content: "Logging in", duration: 0, style: {"minWidth": "30rem"}})
        return loginApi.login(params).then((result) => {
            Env.DEBUG && console.log("账号密码登录成功, result: ", result);
            switch (result.code) {
                case "200":
                    setToken("token");
                    // Cookies.set("google_token", response.credential);
                    navigate("/workbench/chatbot");
                    messageApi.destroy()
                    break;
                case "10000":
                  const { next_step, signup_token } = result.data as any;
                  setRegister({
                    email: "",
                    signup_token
                  })
                  switch(next_step){
                    case "tell_us_more":
                        navigate("/signup/tell_us_more")
                        break;
                    case "verify_email":
                        navigate("/signup/verify_email")
                        break;
                  }
                  break;
                case "10005":
                  navigate(`/addedToQueue`);
                  break;
                default:
                    messageApi.destroy()
                    messageApi.error({content: result.message, duration: 3})
                    setLoading(false)
            }
            
        }).catch(() => {
            messageApi.destroy()
            messageApi.error({content: "Logging failed", duration: 3})
            setLoading(false)
        })
    }

    const goToForgetPassword = () => {
        navigate("/forgetPassword")
    }

    const Step2 = (
        <Form.Form instance={formInstance} onSubmit={doSubmit}>
            <Flex vertical gap={16}>
                <Flex vertical gap={24}>
                    <Flex vertical gap={20}>
                        <Form.Item 
                            label="Email" 
                            name="email"
                            rules={[
                                {validate:required, msg: "Please enter your email"},
                                {validate:email, msg: "Email address must be in the format"}
                            ]}
                        >
                            <Form.Input placeholder={"Enter your email"}/>
                        </Form.Item>
                        <Form.Item 
                            label="Password" 
                            name="password"
                            rules={[
                                {validate:required, msg: "Please enter your password"},
                            ]}    
                        >
                            <Form.Password placeholder={"Enter your password"}/>
                        </Form.Item>
                    </Flex>
                    <Form.Submit>Log in</Form.Submit>
                </Flex>
                <Form.Item label="" name="remember_me" nowrap>
                    <Flex justify="space-between" align="center">
                        <Form.Checkbox name="remember_me">Remember for 30 days</Form.Checkbox>
                        <Link onClick={goToForgetPassword}>Forgot password</Link>
                    </Flex>
                </Form.Item>
            </Flex>
        </Form.Form>
    )

    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['login']}>
                    <Flex align="center" vertical  gap={24}>
                        <Icon size={56} padding={0} src={logoSvg}></Icon>
                        <Flex vertical  gap={12}>
                            <div className={styles['login__title']}>Log in to Leazy.ai</div>
                            <div className={styles['login__subtitle']}>Welcome back! Please enter your details.</div>
                        </Flex>
                    </Flex>

                    <Flex vertical gap={16}>
                        {/* {loginStep==0&&Step1} */}
                        {Step2}
                        <Divider.OR></Divider.OR>
                        {googleLoginBtn}
                    </Flex>

                    <Flex gap={4} justify="center" className={styles['login__signup']}>Don’t have an account?<Link onClick={() => navigate("/signup/first")}>Sign up</Link></Flex>
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}