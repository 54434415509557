
import { Affix, Checkbox, ConfigProvider, Drawer, Flex, FlexProps, Popover, Dropdown, Tooltip } from "antd"
import ProtectPage from "../../components/layout/ProtectPage"
import styles from "./PetitionLetterEditor.module.scss"
import Icon from "../../components/icon/Icon"
import { ClientCaseContext, useClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { createContext, CSSProperties, PropsWithChildren, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { CaseFilingTaskDTO } from "../../api/CaseFilling.api"
import logOutSvg from "../assets/images/log-out-04.svg"
import LoadingIcon from "../../components/icon/LoadingIcon"
import Tabs from "../../components/tabs/Tabs"
import Button from "../../components/Button"
import chevronDownSvg from "../assets/images/chevron-down.svg"
import { useSpring, animated, Any } from "@react-spring/web"
import downloadSvg from "../assets/images/download-01.svg"
import zoomInSvg from "../assets/images/zoom-in.svg"
import zoomOutSvg from "../assets/images/zoom-out.svg"
import Recommenders from "../modules/Recommenders"
import PetitionLetterApi, { AssetDescriptionDTO, AssetDTO, ExhibitDTO as ExhibitDTO, ExhibitRelations, ExhibitsFileDTO, LetterSectionDTO, PetitionLetterDTO } from "../../api/PetitionLetter.api"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import SendSvg from "../assets/images/send-01.svg"
import ExhibitUpload from "./ExhibitUpload"
import xCloseSvg from "../assets/images/x-close.svg"
import file02Svg from "../assets/images/file-02.svg"
import FirstUploadGuideline from "./FirstUploadGuideline"
import Send2ClientGuideline from "./Send2ClientGuideline"
import { useMask } from "../../components/mask/Mask"
import InviteClientUploadExhibit from "./InviteClientUploadExhibit"
import infoCircleSvg from "../assets/images/info-circle.svg"
import send01WhiteSvg from "../assets/images/send-01-white.svg"
import Env from "../../../Env"
import { Swiper, SwiperItem } from "../../components/swiper/Swiper"
import checkCircleSvg from "../assets/images/check-circle.svg"
import fileSearchSvg from "../assets/images/file-search-02.svg"
import EB1APetitionLetter from "./EB1APetitionLetter"
import useBroadcast from "../../components/broadcast/useBroadcast"
import { UserContext } from "../../login/context/UserContext"
import { CaseFilingNotificationContext, useCaseFilingNotificationContext } from "../context/CaseFilingNotificationContext"

interface AnalysisGroupDTO{
    analysis_type: string
    items: AnalysisItemDTO[]
}

interface AnalysisItemDTO{
    id: string
    title: string
    exhibits: AnalysisTag[]
}

interface AnalysisTag{
    id: string
    name: string
    status?: AnalysisTagStatus
    exhibits: number
}

type AnalysisTagStatus = "NEEDED" | "COMPLETED" | "PENDING"

type StatusSelection = {
    [key in AnalysisTagStatus]?: boolean
}

interface SelectionsContextProps{
    taskId?: string
    selection: StatusSelection
    tagSelections: {[key: string]: boolean}
    changeTagSelection: (id: string, selected?: boolean) => void
    highlightId?: string,
    setHighlightId: (id?: string) => void
    detail?: AnalysisTag,
    setDetail: (detail?: AnalysisTag) => void
    changeStatusSelection: (status: AnalysisTagStatus, trueOfFalse: boolean) => void
    assetStatus: {[key: string]: AnalysisTagStatus}
    changeAssetStatus: (status: {[key: string]: AnalysisTagStatus}) => void
    exhibitRelations: ExhibitRelations[]
    petitionLetter?: PetitionLetterDTO
    setPetitionLetter: (petitionLetter: PetitionLetterDTO) => void
    refreshPetitionLetter: () => void
    
}
const SelectionContext = createContext<SelectionsContextProps>({
    selection: {},
    tagSelections: {},
    changeTagSelection: function (id: string, selected?: boolean): void {
        throw new Error("Function not implemented.")
    },
    setHighlightId: function (id?: string): void {
        throw new Error("Function not implemented.")
    },
    setDetail: function (detail?: AnalysisTag): void {
        throw new Error("Function not implemented.")
    },
    taskId: undefined,
    changeStatusSelection: function (status: AnalysisTagStatus, trueOfFalse: boolean): void {
        throw new Error("Function not implemented.")
    },
    assetStatus: {},
    exhibitRelations: [],
    changeAssetStatus: function (status: { [key: string]: AnalysisTagStatus} ): void {
        throw new Error("Function not implemented.")
    },
    setPetitionLetter: function (petitionLetter: PetitionLetterDTO): void {
        throw new Error("Function not implemented.")
    },
    refreshPetitionLetter: function (): void {
        throw new Error("Function not implemented.")
    }
})



export default (
    props: {onClose?: (forceRefresh?: boolean) => void, onChange?: () => void, task: CaseFilingTaskDTO}
) =>{

    const [loading, setLoading] = useState<boolean>(false)
    const [tabIdx, setTabIdx] = useState<number>(0)
    const [groups, setGroups] = useState<AnalysisGroupDTO[]>([])
    const [selection, setSelections] = useState<StatusSelection>({})
    const [tagSelections, setTagSelections] = useState<{[key: string]: boolean}>({})
    const [highlightId, setHighlightId] = useState<string>()
    const [openExhibitUploader, setOpenExhibitUploader] = useState<boolean>(false)
    const [detail, setDetail] = useState<AnalysisTag>()
    const {render: maskRender, show, close} = useMask()
    const [exhibitSelections, setExhibitSelections] = useState<number>(0)
    const [exhibitRelations, setExhibitRelations] = useState<ExhibitRelations[]>([])
    const [notification, setNotification] = useState<number>(0)
    const [statBarSelected, setStatBarSelected] = useState<boolean>(true)
    const [assetStatus, setAssetStatus] = useState<{[key: string]: AnalysisTagStatus}>({})
    const {user} = useContext(UserContext)
    const [petitionLetter, setPetitionLetter] = useState<PetitionLetterDTO>()
    const {letterGenerateMessage, cleanLetterGenerateMessage, analyseSucceeded} = useCaseFilingNotificationContext()
    const [updating, setUpdating] = useState<boolean>(false)
    
    
    const {
        task
    } = props

    const {render, success} = useBroadcast()

    const statusSelectHandle = (status: AnalysisTagStatus, checked: boolean) => {
        setSelections(current => {
            if(current[status] == checked){
                return current
            }
            current[status] = checked
            return {...current}
        })
        //TODO 找到关联的assets进行勾选
        const exhibitSelection: any = {}
        for(const group of groups){
            for(const item of group.items){
                for(const exhibit of item.exhibits){
                    if(exhibit.status === status){
                        exhibitSelection[exhibit.id] = checked
                    }
                }
            }
        }
        setTagSelections((current) => {
            return {
                ...current,
                ...exhibitSelection
            }
        })
        
    }

    const changeTagSelection = (id: string, selected?: boolean) => {
        setTagSelections(current => {
            if(!selected){
                delete current[id]
            }else{
                current[id] = selected
            }
            return {...current}
        })
    }

    const changeStatusSelection = (status: AnalysisTagStatus, trueOfFalse: boolean) => {
        setSelections(current => {
            return {
                ...current,
                [status]: trueOfFalse
            }
        })
    }

    const checkAssetsStatus = async (exhibitIds: string[]) => {
        //和exhibitIds关联的itemIds都要做一次状态的check
        const relatedItemIds: any = {}
        for(const changedExhibitId of exhibitIds){
            const relation = exhibitRelations.find(it => it.id === changedExhibitId)
            relation?.related_item_ids.forEach(it => relatedItemIds[it] = true)
        }
        const relatedAssetStatus: any = {}
        for(const relatedItemId of Object.keys(relatedItemIds)){
            const asset = await PetitionLetterApi.asset({case_filing_id: task.id, item_id: relatedItemId})
            relatedAssetStatus[asset.item_id] = asset.status
            changeAssetStatus(relatedAssetStatus)
        }
    }

    const doSend2Client = async (email: string, exhibitIds: string[]) => {

        return PetitionLetterApi.send2Client(task.id, {recipient_email: email, exhibit_ids: exhibitIds}).then(async () => {
            setNotification(1);
            close()
            checkAssetsStatus(exhibitIds)
        })
        //TODO chihsijie 缺api
    }

    const openSend2client = (tagSelections: {[key: string]: boolean}, exhibitSelections: {[key: string]: boolean} = {}) => {


        show(<InviteClientUploadExhibit 
            relations={exhibitRelations} 
            tagSelections={tagSelections} 
            exhibitSelections={exhibitSelections}
            onCancel={() => {close()}}
            onConfirm={doSend2Client}
            />
            
        )
    }

    const cleanTagSelection = (checked: boolean) => {
        if(!checked){
            setStatBarSelected(false)
            setTagSelections({})
            setSelections({})
        }
    }

    const changeAssetStatus = (changeAssetStatus: {[key: string]: AnalysisTagStatus}) => {
        setAssetStatus(current => {
            return {
                ...current,
                ...changeAssetStatus
            }
        })
    }

    const updateLetter = async () => {
        if(updating) return;
        setUpdating(true)
        return PetitionLetterApi.updateLetter(task.id).then(() => {
            success({content: "Success to submit petition letter generation task"})
            return refreshPetitionLetter().then(() => {
                setNotification(2)
                setUpdating(false)
            })
        })
    }

    const refreshPetitionLetter = async () => {
        return PetitionLetterApi.getLetter(task.id as string).then(letter => {
            setPetitionLetter(letter)
        })
    }


    useEffect(() => {
        
        PetitionLetterApi.analysisList(task.id).then(async (groups: AnalysisGroupDTO[]) => {
            const assetsMapping: {[key: string]: AnalysisTag} = {}
            const assetStatusMapping: {[key: string]: AnalysisTagStatus} = {}
            for(const group of groups){
                group.items.forEach(item => {
                    item.exhibits.forEach(asset => {
                        assetsMapping[asset.id] = asset
                        if(asset.status){
                            assetStatusMapping[asset.id] = asset.status
                        }
                    })
                })
            }
            
            const _exhibitsRelations = await PetitionLetterApi.exhibits(task.id)
            for(const relation of _exhibitsRelations){
                relation.related_item_ids.forEach(assetId => {
                    assetsMapping[assetId].exhibits = assetsMapping[assetId].exhibits??0 + 1
                })
            }

            setGroups(groups)
            setExhibitRelations(_exhibitsRelations)
            setAssetStatus(assetStatusMapping)

            if(task.evidence_status === "SUCCEEDED"){
                refreshPetitionLetter()
            }
            
        })
        
    }, [])

    useEffect(() => {
        if(detail){
            setOpenExhibitUploader(true)
        }else{
            setOpenExhibitUploader(false)
        }
    }, [detail])

    useEffect(() => {
        // if(tagSelections){
        //     console.log("tagSelections changed: ", tagSelections)
        //     const itemIds = Object.keys(tagSelections).filter(([key, value]) => value).map(([key, value]) => key)
        //     if(itemIds && itemIds.length > 0){
        //     }
        // }
        let _exhibitSelections = 0;
        for(const group of groups){
            for(const item of group.items){
                for(const exhibit of item.exhibits){
                    if(tagSelections[exhibit.id]){
                        _exhibitSelections += exhibit.exhibits
                    }
                }
            }
        }
        setExhibitSelections(_exhibitSelections)

        if(_exhibitSelections > 0){
            setStatBarSelected(true)
        }else{
            setStatBarSelected(false)
        }

    }, [tagSelections])

    useEffect(() => {
        // if(tagSelections){
        //     console.log("tagSelections changed: ", tagSelections)
        //     const itemIds = Object.keys(tagSelections).filter(([key, value]) => value).map(([key, value]) => key)
        //     if(itemIds && itemIds.length > 0){
        //     }
        // }
        // Env.DEBUG && console.log("selection changed: ", selection)
    }, [selection])

    useEffect(() => {

        if(letterGenerateMessage){
            refreshPetitionLetter()
            cleanLetterGenerateMessage() 
            setNotification(0)

            if(letterGenerateMessage.exhibit_ids){
                checkAssetsStatus(letterGenerateMessage.exhibit_ids)
            }
        }

    }, [letterGenerateMessage])


    const leftScreenRender = () => {
        return (
            <Flex vertical gap={16} className={styles['left-screen']}>
                {tabsRender()}
                {contentRender()}
                {notificationRender()}
            </Flex>
        )
    }

    const notificationRender = () => {

        if(notification == 1){
            return (
                <div className={styles['notification-wrapper']}>
                    <Flex align="center" className={[styles['notification'], styles['notification_success']].join(" ")} >
                        <Flex align="center" flex={1} gap={12}>
                            <Icon src={checkCircleSvg} size={20}></Icon>
                            Your exhibit lists have been sent to client successfully.
                        </Flex>
                        <Icon src={xCloseSvg} size={20} onClick={() => setNotification(0)}></Icon>
                    </Flex>
    
                </div>
            )
        }else if(notification == 2){
            return (
                <div className={styles['notification-wrapper']}>
                    <Flex align="center" className={[styles['notification'], styles['notification_letter-updating']].join(" ")} >
                        <Flex align="center" flex={1} gap={12}>
                            <Icon src={infoCircleSvg} size={20}></Icon>
                            Your AI petition letter is being generated and will be ready soon.
                        </Flex>
                        <Icon src={xCloseSvg} size={20} onClick={() => setNotification(0)}></Icon>
                    </Flex>
    
                </div>
            )
        }

        return <></>
    }

    const tabsRender = () => {
        return (
            <Flex align="center" justify="space-between">
                <Tabs items={['Evidence editor', 'Recommender']} onChange={setTabIdx}></Tabs>   
                {
                    //TODO chishiji3e
                    user?.guide_tour_status.PETITION_LETTER_UPDATE
                    ?
                    <div>
                        <Button.Primary 
                            loading={updating}
                            disabled={!(petitionLetter?.can_update_petition_letter)} width={122} padding={[10, 16]} onClick={updateLetter}>
                                <Flex align="flex-start" justify="center" >
                                    Update
                                    {(petitionLetter?.can_update_petition_letter) && <div style={{width: 6, height: 6, borderRadius: 6, background:"#fff"}}></div>}
                                </Flex>
                            </Button.Primary>
                    </div>
                    :
                    <Popover
                        trigger="hover"
                        content={
                            <FirstUploadGuideline onConfirm={updateLetter}/>
                        } 
                        placement="bottom"
                        arrow={true} 
                        overlayInnerStyle={{"padding": 8}}>
                        <div>
                            <Button.Primary 
                                disabled={!(petitionLetter?.can_update_petition_letter)} width={122} padding={[10, 16]} onClick={updateLetter}>
                                    <Flex align="flex-start" justify="center" >
                                        Update
                                        {(petitionLetter?.can_update_petition_letter) && <div style={{width: 6, height: 6, borderRadius: 6, background:"#fff"}}></div>}
                                    </Flex>
                                </Button.Primary>
                        </div>
                    </Popover>
                }
                
                
            </Flex>
        )
    }

    const contentRender = () =>{

        return (
            <Swiper currentIdx={tabIdx}>
                <SwiperItem idx={0}>
                    {editorRender()}
                </SwiperItem>
                <SwiperItem idx={1}>
                    {<Recommenders task={task}/>}
                </SwiperItem>
            </Swiper>
        )

    }

    const editorRender = () => {

        if(task.evidence_status !== "SUCCEEDED"){
            return (
                <Flex vertical gap={16}>
                    <label className={styles['editor__processing__title']}>Please wait a moment as we take some time 🕑 to process your request. It might take a few minutes, but rest assured, we will notify you via email as soon as the results are available. We appreciate your patience and understanding. </label>
                    <label className={styles['editor__processing__subtitle']}>Petition letter generation in progress...</label>
                </Flex>
            )
        }else{
            return (
                <Flex vertical gap={16} style={{height: "100%"}}>
                        {editorStatusBarRender()}
                        {editorAnalysisBoxRender()}
                    </Flex>
                
            )
        }
        
        
    }

    const editorStatusBarRender = () => {
        return (
            <Flex className={styles["editor__statusbar"]} gap={32} align="center">
                <label className={styles["editor__statusbar__label"]}>Select by</label>
                <Flex gap={16} align="center">
                    <Checkbox checked={selection["NEEDED"]} onChange={(e) => statusSelectHandle("NEEDED", e.target.checked)}><label className={[styles['editor__statusbar__status'], styles['editor__statusbar__status_NEEDED']].join(" ")}>Need exhibit</label></Checkbox>
                    <Checkbox checked={selection["PENDING"]} onChange={(e) => statusSelectHandle("PENDING", e.target.checked)}><label className={[styles['editor__statusbar__status'], styles['editor__statusbar__status_PENDING']].join(" ")}>Pending</label></Checkbox>
                    <Checkbox checked={selection["COMPLETED"]} onChange={(e) => statusSelectHandle("COMPLETED", e.target.checked)}><label className={[styles['editor__statusbar__status'], styles['editor__statusbar__status_COMPLETED']].join(" ")}>Completed</label></Checkbox>
                </Flex>
            </Flex>
        )
    }

    const editorAnalysisBoxRender = () => {

        return (
            <div className={styles['editor__analysisbox-wrapper']} style={{flex: 1, minHeight: 1}}>
                <div className={styles['editor__analysisbox']} >
                    <div className={styles['editor__analysisbox__header']}>
                        {rowRender(
                            <label className={styles['editor__analysisbox__label_type']}>Analysis type</label>,
                            <label className={styles['editor__analysisbox__label_status']}>Status</label>
                        )}
                    </div>
                    <div className={styles['editor__analysisbox__content']}>
                        {
                            groups.map(group => <AnalysisGroup taskId={task.id} data={group} />)
                        }
                    </div>
                </div>
                <Affix style={{position: "sticky", left: 0, right: 0, bottom: 130}}>{tagSelectionStatBarRender()}</Affix>
            </div>
        )
    }

    const tagSelectionStatBarRender = () => {
        if(exhibitSelections > 0){
            return (
                <Flex className={styles['editor__analysisbox__statbar']} align="center" gap={16}>
                    <Flex className={styles['editor__analysisbox__statbar__stat']} align="center" gap={8}>
                        <Checkbox checked={statBarSelected} onChange={(e) => cleanTagSelection(e.target.checked)}></Checkbox>
                        <label>{exhibitSelections} Selected</label>
                    </Flex>
                    <div className={styles['editor__analysisbox__statbar__divider']}></div>
                    <Flex 
                        align="center" 
                        gap={8} 
                        className={styles['editor__analysisbox__statbar__icon']}
                        onClick={() => openSend2client(tagSelections)}
                        >
                        <Icon src={send01WhiteSvg} size={20}/>
                        <label>Send to client</label>
                    </Flex>
                </Flex>
            )
        }else{
            return <></>
        }
        
    }

    const rowRender = (left: ReactNode, right: ReactNode) => {
        return (
            <Flex align="center" className={styles['editor__analysisbox__row']}>
                <div className={styles['editor__analysisbox__row__type']} style={{flex: 1}}>{left}</div>
                <div className={styles['editor__analysisbox__row__status']}>{right}</div>
            </Flex>
        )
    }

    const rightScreenRender = () => {
        return (
            <div className={styles['right-screen']} style={{flex: 1}}>
                {
                    tabIdx == 0 && <PetitionLetterReader/>
                }
                <ConfigProvider
                    theme={{
                        token: {
                        /* 这里是你的全局 token */
                            padding: 0,
                            paddingLG: 0,
                            paddingMD: 0
                        },
                    }}
                    >
                    <Drawer 
                        getContainer={false}
                        open={openExhibitUploader}
                        push={{distance: 0}}
                        closable={false}
                        width={600}
                        >
                        {detail && <AssetPreview key={detail.id} caseFilingId={task.id} tag={detail} onClose={() => {
                            setOpenExhibitUploader(false)
                            setDetail(undefined)
                        }} onSend2Client={(exhibitSelections) => openSend2client({}, exhibitSelections)}/>}
                    </Drawer>
                </ConfigProvider>
                
            </div>
        )
    }

    return (
        <ProtectPage className={styles["form"]}>
            <Flex vertical style={{height: "100%"}}>
                <Flex gap={32} align="center" className={styles["form__header"]}>
                    <Icon size={24} src={logOutSvg} onClick={() => props.onClose?.()}></Icon>
                    Petition Letter Generation
                </Flex>       
                <Flex style={{flex: 1, minHeight: 1}}>
                    {
                        loading
                        ?
                        <div style={{padding: 28}}><LoadingIcon/></div>
                        :
                        task.evidence_status === "SUCCEEDED"
                        ?
                        <SelectionContext.Provider value={{
                            taskId: task.id,
                            selection, 
                            tagSelections, 
                            changeTagSelection, 
                            highlightId, 
                            setHighlightId, 
                            detail, 
                            setDetail,
                            changeStatusSelection,
                            assetStatus,
                            changeAssetStatus,
                            exhibitRelations,
                            petitionLetter,
                            setPetitionLetter,
                            refreshPetitionLetter
                            }}>
                            <Flex style={{width: "100%", height: "100%"}}>
                                {leftScreenRender()}
                                {rightScreenRender()}
                            </Flex> 
                        </SelectionContext.Provider>
                        :
                        <Flex vertical gap={16} style={{padding: "28px 16px"}}>
                            <label className={styles['editor__processing__title']}>Please wait a moment as we take some time 🕑 to process your request. It might take a few minutes, but rest assured, we will notify you via email as soon as the results are available. We appreciate your patience and understanding. </label>
                            <label className={styles['editor__processing__subtitle']}>Petition letter generation in progress...</label>
                        </Flex>
                    }
                </Flex>     
            </Flex>
            {maskRender}
            {render}
        </ProtectPage>
    )
}



const AnalysisGroup = (props: {taskId: string, data: AnalysisGroupDTO}) => {

    const {
        taskId,
        data
    } = props

    const {
        analysis_type
    } = data

    const {selection, tagSelections, changeTagSelection, setHighlightId, detail, setDetail, changeStatusSelection, assetStatus} = useContext(SelectionContext)

    return (
        <>
            <AnalysisRow
                type={<AnalysisRowType id="" title={analysis_type} level={1} />}
                status={<AnalysisRowStatus ></AnalysisRowStatus>}
            >
                {
                    data.items.map(item => (
                        <AnalysisRow
                            type={<AnalysisRowType id={item.id} title={item.title} level={2} hasAssets={item.exhibits && item.exhibits.length > 0} />}
                            status={<AnalysisRowStatus ></AnalysisRowStatus>}
                            item={true}
                            
                        >
                            {
                                item.exhibits.map(tag => (
                                    <AnalysisRow
                                        type={<AnalysisRowTypeTag title={tag.name} id={tag.id} status={tag.status} tag={tag} selected={tagSelections[tag.id]}/>}
                                        status={<AnalysisRowStatus status={assetStatus[tag.id]}></AnalysisRowStatus>}
                                        tag
                                    ></AnalysisRow>
                                ))
                            }
                        </AnalysisRow>
                    ))
                }
            </AnalysisRow>
        </>
    )
}

interface AnalysisRowContextData{
    setExpend(expend: boolean):void
    expend: boolean
}
const AnalysisRowContext = createContext<AnalysisRowContextData>({
    setExpend: function (expend: boolean): void {
        throw new Error("Function not implemented.")
    },
    expend: false
})

const AnalysisRow = (props: PropsWithChildren<{type: ReactNode, status: ReactNode, tag?: boolean, item?: boolean}>) => {

    const {
        type,
        status,
        children,
        tag,
        item
    } = props
    const [expend, setExpend] = useState<boolean>(true)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const singleRowHeight = 40

    const [springs, api] = useSpring(() => ({
        from: {maxHeight: 9999 },
      }))

    

    useEffect(() => {
        if(!expend){
            const currentMaxHeight = wrapperRef.current?.children[0].getBoundingClientRect().height
            api.start({
                from: {maxHeight: currentMaxHeight},
                to: {maxHeight: singleRowHeight},
                config:{
                    duration: 150
                }
            })
        }else{
            const currentMaxHeight = wrapperRef.current?.children[0].getBoundingClientRect().height
            api.start({
                to: {maxHeight: currentMaxHeight},
                onResolve(){
                    api.set({
                        maxHeight: 9999
                    })
                },
                config:{
                    duration: 150
                }
            })
        }
    }, [expend])

    return (
        <animated.div ref={wrapperRef} className={styles['editor__analysisbox__row__wrapper']} style={springs}>
            <div>
                <AnalysisRowContext.Provider value={{expend: !!expend, setExpend}}>
                    <Flex 
                        align="center" 
                        className={[
                            styles['editor__analysisbox__row'], 
                            tag?styles['editor__analysisbox__row_tag']:"",
                            item?styles['editor__analysisbox__row_item']:""
                        ].join(" ")}
                    >
                        <Flex className={styles['editor__analysisbox__row__type']} style={{flex: 1}} align="center">{type}</Flex>
                        <Flex className={[styles['editor__analysisbox__row__status']].join(" ")} align="center" justify="center">{status}</Flex>
                    </Flex>
                </AnalysisRowContext.Provider>
                {children}
            </div>
        </animated.div>
    )
}

const AnalysisRowType = (props: {id: string, title: string, level: number, hasAssets?: boolean}) => {
    
    const {
        id,
        title,
        level,
        hasAssets
    } = props
    const {changeTagSelection, setHighlightId, detail, setDetail, changeStatusSelection} = useContext(SelectionContext)
    const {expend, setExpend} = useContext(AnalysisRowContext)
    const [hovered, setHovered] = useState<boolean>()
    const mouseEnterHandle: React.MouseEventHandler<HTMLDivElement>  = (e) => {
        setHovered(true)
    }
    const mouseLeaveHandle: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setHovered(false)
    }

    const highlightRender = () => {

        const detailClickHandle = () => {
            setHighlightId(id)
        }

        if(!hovered) return <></>

        return (
            <div className={styles['editor__analysisbox__row__type__highlight-wrapper']} onClick={detailClickHandle}>
                <Flex className={styles['editor__analysisbox__row__type__highlight']} align="center" gap={8} >
                    <Icon src={fileSearchSvg} size={20}></Icon>
                    highlight
                </Flex>
            </div>
        )
    }

    return (
        <Flex 
            gap={8} 
            align="center" 
            onMouseEnter={mouseEnterHandle} 
            onMouseLeave={mouseLeaveHandle}
            >
            <Icon 
                src={chevronDownSvg} 
                size={20} 
                className={[styles['editor__analysisbox__row__type__icon'], expend?styles['editor__analysisbox__row__type__icon_expend']:''].join(" ")}
                onClick={() => setExpend(!expend)}
                ></Icon>
                
                <Tooltip title={level == 2?title:undefined} overlayStyle={{minWidth: 434}}>
                    <Flex align="center" className={[styles[`editor__analysisbox__row__type_${level}`]].join(" ")}>
                        <div className={[styles[`editor__analysisbox__row__type_${level}__label`]].join(" ")}>{title}</div>
                        {level == 2 && hasAssets && highlightRender()}
                    </Flex>
                </Tooltip>
        </Flex>
    )
}

const AnalysisRowTypeTag = (props: {
    id: string, 
    title: string, 
    status?: AnalysisTagStatus, 
    tag: AnalysisTag
    selected: boolean
}) => {

    const {changeTagSelection, setHighlightId, detail, setDetail, changeStatusSelection} = useContext(SelectionContext)
    const {
        id,
        title,
        status,
        tag,
        selected
    } = props
    const [hovered, setHovered] = useState<boolean>()
    const mouseEnterHandle: React.MouseEventHandler<HTMLDivElement>  = (e) => {
        setHovered(true)
    }
    const mouseLeaveHandle: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setHovered(false)
    }

    const detailRender = () => {

        const detailClickHandle = () => {
            setDetail(tag)
        }

        if(!hovered) return <></>

        return (
            <Flex className={styles['editor__analysisbox__row__type_tag__detail']} align="center" gap={8} onClick={detailClickHandle}>
                <Icon src={file02Svg} size={20}></Icon>
                detail
            </Flex>
        )
    }

    return (
        <Flex 
            gap={8} 
            align="center" 
            className={[styles[`editor__analysisbox__row__type_tag`]].join(" ")}
            onMouseEnter={mouseEnterHandle} 
            onMouseLeave={mouseLeaveHandle}
            justify="space-between"
            style={{"width": "100%"}}
            >
            <div style={{flex: 1}}>
                <Checkbox 
                    checked={selected} 
                    onChange={e => {
                        // setSelected(e.target.checked)
                        changeTagSelection(id, e.target.checked);
                        // setHighlightId(e.target.checked?id:undefined)
                        if(!e.target.checked){
                            status && changeStatusSelection(status, false)
                        }
                        // setDetail(e.target.checked?tag:undefined)
                    }}
                    > <Tooltip overlayStyle={{minWidth: 409}} title={title}><div className={[styles[`editor__analysisbox__row__type_tag__label`]].join(" ")}>{title}</div></Tooltip> </Checkbox>
            </div>
            {detailRender()}
        </Flex>
    )
}


const AnalysisRowStatus = (props: {status?: AnalysisTagStatus}) => {

    const {
        status
    } = props

    const labelRender = () => {
        switch(status){
            case 'NEEDED':
                return <div className={[styles['editor__analysisbox__row__status__label'], styles['editor__analysisbox__row__status__label_NEEDED']].join(" ")}>Need exhibit</div>;
            case 'PENDING':
                return <div className={[styles['editor__analysisbox__row__status__label'], styles['editor__analysisbox__row__status__label_PENDING']].join(" ")}>Pending</div>;
            case 'COMPLETED':
                return <div className={[styles['editor__analysisbox__row__status__label'], styles['editor__analysisbox__row__status__label_COMPLETED']].join(" ")}>Completed</div>;
            default:
                return <></>
        }
    }

    if(!status) return <></>
    
    return labelRender()
}


const PetitionLetterReader = () => {

    const [scale, setScale] = useState(1)
    const {taskId, highlightId, petitionLetter, setPetitionLetter} = useContext(SelectionContext)
    const {client} = useContext(ClientCaseContext)

    const zoomOutHandle = () => {
        setScale(Math.max(scale - 0.1, 0.5))
    }

    const zoomIntHandle = () => {
        setScale(Math.min(scale + 0.1, 1.5))
    }

    const download = () => {
        
        taskId && PetitionLetterApi.download(taskId).then(res => {
            console.log("download link: ", res.pdf_url)
            window.open(res.pdf_url, "_blank")
        })
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log("pageRef: ");
    //         const element = window.document.getElementById("#1001");
    //         console.log("element: ", element)
    //         console.log("view: ", pageRef.current)
    //     }, 10000);
    // }, [])

    useEffect(() => {
        // if(highlightId){
        //     window.document.querySelector(`#${highlightId}`)?.scrollIntoView({behavior: "smooth"})
        // }
        console.log("highlightId: ", highlightId)        
    }, [highlightId])

    useEffect(() => {
        
    }, [])

    const document = () => {
        if(client && petitionLetter){
            return (
                <EB1APetitionLetter 
                    lastName={client.last_name} 
                    firstName={client.first_name} 
                    letter={petitionLetter} scale={scale}
                    highlightAssetId={highlightId}
                    ></EB1APetitionLetter>
            )
        }
        return (
            <></>
        )
    }

    return (
        <Flex vertical className={styles['petition-letter-reader']}>
            <Flex className={styles['petition-letter-reader__header']} justify="space-between" align="center">
                <Flex vertical gap={4}>
                    <div className={styles['petition-letter-reader__label']}>Petition Letter Preview</div>
                    <div className={styles['petition-letter-reader__time']}>Last updated at yesterday 11:30</div>
                </Flex>
                <Icon src={downloadSvg} size={20} padding={10} onClick={download}></Icon>
                {/* <PDFDownloadLink document={document}><Icon src={downloadSvg} size={20} padding={10}></Icon></PDFDownloadLink> */}
                
            </Flex>
            <div className={styles['petition-letter-reader__body']} style={{flex: 1, minHeight: 1}}>
                <div className={styles['petition-letter-reader__content']}>
                    {document()}
                    <Flex vertical className={styles['petition-letter-reader__tools']} gap={8} align="center">
                        {/* <Flex className={styles['petition-letter-reader__tools__pagination']} vertical gap={2} align="center">
                            <label>1</label>
                            <label>/</label>
                            <label>5</label>
                        </Flex>
                        <div className={styles['petition-letter-reader__tools__divide']}></div> */}
                        <Icon src={zoomOutSvg} size={20} onClick={zoomOutHandle}></Icon>
                        <Icon src={zoomInSvg} size={20} onClick={zoomIntHandle}></Icon>
                    </Flex>
                </div>
            </div>
        </Flex>
    )
}


export const AssetPreview = (props: {caseFilingId: string, tag: AnalysisTag, onClose: Function, onSend2Client: (tagSelections: {[key: string]: boolean}) => void}) =>{

    const {
        caseFilingId,
        tag,
        onClose,
        onSend2Client
    } = props

    const [tabIdx, setTabIdx] = useState<number>(0)
    const [asset, setAsset] = useState<AssetDTO>() 
    const {assetStatus} = useContext(SelectionContext)

    useEffect(() => {
        if(caseFilingId){
            PetitionLetterApi.asset({case_filing_id: caseFilingId, item_id: tag.id}).then(setAsset)
        }
        
    }, [])

    useEffect(() => {
        console.log("[AssetPreview] assetStatus changed: ", assetStatus[tag.id])
    }, [assetStatus])

    const labelRender = (status?: AnalysisTagStatus) => {
        switch(status){
            case 'NEEDED':
                return <label className={[styles['asset-preview__status'], styles[`asset-preview__status_${status}`]].join(" ")}>Need exhibit</label>
            case 'PENDING':
                return <label className={[styles['asset-preview__status'], styles[`asset-preview__status_${status}`]].join(" ")}>Pending</label>
            case 'COMPLETED':
                return <label className={[styles['asset-preview__status'], styles[`asset-preview__status_${status}`]].join(" ")}>Completed</label>
            default:
                return <></>
        }
    }

    return (
        <Flex vertical className={styles['asset-preview']}>
            <Affix style={{position: "sticky", left: 0, right: 0, top: 0, zIndex: 100}}>
                <Flex vertical gap={16} className={styles['asset-preview__header']}>
                    <Flex align="center" justify="space-between">
                        <Flex gap={8} align="center">
                            <label className={styles['asset-preview__title']}>{tag.name}</label>
                            {labelRender(assetStatus[tag.id])}
                        </Flex>
                        <Icon src={xCloseSvg} size={20} padding={8} hoverable onClick={() => onClose()}/>
                    </Flex>
                    <div className={styles['asset-preview__tabs']}>
                        <Tabs onChange={setTabIdx} items={["Evidence", "Exhibit"]}/>
                    </div>
                </Flex>
            </Affix>
            <div className={styles['asset-preview__body']} style={{flex: 1, height: "100%"}}>
                {
                    tabIdx == 0
                    ?
                    <Flex className={styles['evidence-preview__evidencebox']} vertical gap={16}>
                        {
                            asset?.descriptions.map((evidence, idx) => <EvidencePreview idx={idx} evidence={evidence}/>)
                        }
                    </Flex>
                    :
                    asset?.exhibits && <ExhibitsPreview taskId={caseFilingId} assetId={tag.id} exhibits={asset?.exhibits} onSend2Client={onSend2Client}></ExhibitsPreview>
                }
                
            </div>

        </Flex>
    )
}


export const EvidencePreview = (props: {idx: number, evidence: AssetDescriptionDTO}) => {

    const [expend, setExpend] = useState<boolean>(true)
    const previewRef = useRef<HTMLDivElement>(null)
    const {
        idx,
        evidence
    } = props

    const [cssProps, api] = useSpring(
        () => ({
            from: { maxHeight: 9999 },
        }),
        []
    )

    const expendHandle = () => {
        const next = !expend
        setExpend(next)

        if(next){
            const height = previewRef.current?.getBoundingClientRect().height
            api.start({
                from: {
                    maxHeight: 20
                },
                to: {
                    maxHeight: height
                },
                config: {
                    duration: 10
                },
                onResolve(){
                    api.set({maxHeight: 9999})
                }
            })
        }else{
            const height = previewRef.current?.getBoundingClientRect().height
            api.start({
                from: {
                    maxHeight: height
                },
                to: {
                    maxHeight: 20
                },
                config: {
                    duration: 10
                },
            })
        }
    }
    

    return (
        <animated.div style={cssProps} className={styles['evidence-preview-wrapper']}>
            <Flex className={[styles['evidence-preview'], !expend?styles['evidence-preview_hidden']:""].join(" ")} vertical gap={12} ref={previewRef}>
                <Flex gap={8} align="center">
                    <Icon 
                        src={chevronDownSvg} 
                        size={20} 
                        className={[styles['evidence-preview__icon'], !expend?styles['evidence-preview__icon_hidden']:""].join(" ")}
                        onClick={() => expendHandle()}
                        ></Icon>
                    <div className={styles['evidence-preview__title']}>{idx + 1}. {evidence.title}</div>
                </Flex>
                <div className={styles['evidence-preview__value']}>{evidence.value}</div>
            </Flex>
        </animated.div>
        
    )
}

interface ExhibitChange{
    exhibit: ExhibitDTO
    text?: string
    addFiles: string[]
    removeFiles: string[] 
}

const ExhibitsPreview = (props: {taskId: string, assetId: string, exhibits: ExhibitDTO[], onSend2Client: (tagSelections: {[key: string]: boolean}) => void}) => {

    const{
        taskId,
        exhibits,
        onSend2Client,
        assetId
    } = props

    const formInstance = useFormInstance()
    const [changes, setChanges] = useState<{[key: string]: ExhibitChange}>({})
    const {changeAssetStatus, exhibitRelations, refreshPetitionLetter} = useContext(SelectionContext)
    const {user} = useContext(UserContext)

    const handleSend2Client = (e: any, exhibit: ExhibitDTO) => {
        e.stopPropagation()
        e.preventDefault()
        onSend2Client({[exhibit.id]: true})
    }

    const doSubmit = async (form: any) => {
        for(let [exhibitId, change] of Object.entries(changes)){
            if(change.exhibit.exhibit_input_type === "FILE"){
                for(let addFile of change.addFiles){
                    await PetitionLetterApi.uploadExhibitFile(taskId, exhibitId, addFile)
                }
                for(let deleteFile of change.removeFiles){
                    await PetitionLetterApi.deleteExhibitFile(taskId, exhibitId, deleteFile)
                }
            }else{
                await PetitionLetterApi.updateExhibitText(taskId, exhibitId, change.text??"")
            }
        }

        setChanges({})
        
        //和exhibitIds关联的itemIds都要做一次状态的check
        const relatedItemIds: any = {}
        for(const changedExhibitId of Object.keys(changes)){
            const relation = exhibitRelations.find(it => it.id === changedExhibitId)
            relation?.related_item_ids.forEach(it => relatedItemIds[it] = true)
        }
        const relatedAssetStatus: any = {}
        for(const relatedItemId of Object.keys(relatedItemIds)){
            const asset = await PetitionLetterApi.asset({case_filing_id: taskId, item_id: relatedItemId})
            relatedAssetStatus[asset.item_id] = asset.status
            changeAssetStatus(relatedAssetStatus)
        }

        //更新letter
        refreshPetitionLetter()
        
    }

    const fileAddHandle = (exhibit: ExhibitDTO, fileId: string) => {
        // PetitionLetterApi.uploadExhibitFile(taskId, exhibit.id, fileId).then(() => {
        //     Env.DEBUG && console.log("uploadExhibitFile success")
        // })
        console.log("fileAddHandle: ")
        const current = changes[exhibit.id]?? {exhibit, addFiles: [], removeFiles: []}
        current.addFiles.push(fileId)
        console.log("fileAddHandle, current: ", current)
        setChanges({
            ...changes,
            [exhibit.id]: {...current}
        })
    }

    const fileDeleteHandle = (exhibit: ExhibitDTO, fileId: string) => {
        const current = changes[exhibit.id]?? {exhibit, addFiles: [], removeFiles: []}
        current.removeFiles.push(fileId)
        console.log("fileDeleteHandle, current: ", current)
        setChanges({
            ...changes,
            [exhibit.id]: {...current}
        })
    }

    const textChangeHandle = (exhibit: ExhibitDTO, text?: string) => {
        const current = changes[exhibit.id]?? {exhibit, addFiles: [], removeFiles: []}
        current.text = text
        console.log("textChangeHandle, current: ", current)
        setChanges({
            ...changes,
            [exhibit.id]: {...current}
        })
    }


    useEffect(() => {
        let form: any = {}
        exhibits.forEach(exhibit => {
            switch(exhibit.exhibit_input_type){
                case "FILE":
                    form[exhibit.id] = exhibit.files
                    return
                default:
                    //TODO
            }
        })
        Env.DEBUG && console.log("initialData: ", form)
        formInstance.setInitial(form)
    }, [])

    const send2ClientRender = (exhibit: ExhibitDTO) => {
        
        return (
            //TODO chishijie
            user?.guide_tour_status.SEND_TO_CLIENT
            ?
            <div>
                <Flex className={styles["exhibits-preview__send2client"]} gap={8} align="center" onClickCapture={(e) => {handleSend2Client(e, exhibit)}}>
                    <Icon src={SendSvg} size={20}/>
                    Send to client
                </Flex>
            </div>
            :
            <Popover
                trigger="hover"
                content={
                    <Send2ClientGuideline onConfirm={() => onSend2Client({[exhibit.id]: true})}/>
                } 
                placement="bottom"
                arrow={true} 
                overlayInnerStyle={{"padding": 8}}>
                <div>
                    <Flex className={styles["exhibits-preview__send2client"]} gap={8} align="center" onClickCapture={(e) => {handleSend2Client(e, exhibit)}}>
                        <Icon src={SendSvg} size={20}/>
                        Send to client
                    </Flex>
                </div>
            </Popover>
            
        )
    }

    return (
        <Form.Form instance={formInstance} showError={false} onSubmit={doSubmit}>
            <Flex vertical style={{height: "100%"}}>
                {/* <div className={styles['exhibit-textarea-preview__label']}>Abstract of the paper</div> */}
                <Flex vertical gap={12} className={styles['exhibits-preview__form']} style={{flex: 1}}>
                    {
                        exhibits.map(exhibit => {
                            if(exhibit.exhibit_input_type === "FILE"){
                                return (
                                    <Form.Item label={exhibit.exhibit_name} name={exhibit.id} >
                                        <ExhibitUpload 
                                            extraRender={send2ClientRender(exhibit)} 
                                            onFileAdd={(fileId: string) => {fileAddHandle(exhibit, fileId)}} 
                                            onFileDelete={(fileId: string) => {fileDeleteHandle(exhibit, fileId)}} 
                                        />
                                    </Form.Item>
                                )
                            }else{
                                return (
                                    <Form.Item label={exhibit.exhibit_name} name={exhibit.id}>
                                        <Form.TextArea onChange={(e) => {textChangeHandle(exhibit, e.target.value)}} />
                                    </Form.Item>
                                )
                            }
                        })
                    }
                    
                </Flex>
                <Affix style={{position: "absolute", bottom: 0, left: 0, right: 0}}>
                    <Flex className={styles['exhibits-preview__btn-wrapper']} justify="flex-end">
                        <Form.Item nowrap label="" name="">
                            <Form.Submit style={{padding: "10px 16px", width: "122px"}}>Submit</Form.Submit>
                        </Form.Item>
                    </Flex>
                </Affix>
            </Flex>
            
        </Form.Form>
    )
}

