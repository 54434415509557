import { CSSProperties, ReactNode, useEffect, useState } from "react"
import { LetterSectionDTO, PetitionLetterDTO } from "../../api/PetitionLetter.api"

import styles from "./EB1APetitionLetter.module.scss"
import { Skeleton } from "antd";

const all_criterion = [
    "Evidence of receipt of lesser nationally or internationally recognized prizes or awards for excellence in the field of endeavor",
    "Evidence of petitioner's membership in associations in the field which demand outstanding achievement of their members",
    "Evidence of published material about the petitioner in professional or major trade publications or other major media",
    "Evidence that the petitioner has been asked to judge the work of others, either individually or on a panel",
    "Evidence of the petitioner's original scientific, scholarly, artistic, athletic, or business-related contributions of major significance to the field",
    "Evidence of the petitioner's authorship of scholarly articles in professional or major trade publications or other major media",
    "Evidence that the petitioner's work has been displayed at artistic exhibitions or showcases",
    "Evidence of the petitioner's performance of a leading or critical role in distinguished organizations or establishments that have a distinguished reputation",
    "Evidence that the petitioner commands a high salary or other significantly high remuneration in relation to others in the field",
    "Evidence of the petitioner's commercial successes in the performing arts"
]

function numberToWord(num: number) {
    // Map single-digit numbers to words
    var words = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

    // Check if the number is within range
    if (num >= 0 && num <= 10) {
        return words[num];
    } else {
        return "Number out of range";
    }
}

const exhibitLetters = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']; // Letters for exhibits

function getFullCriterionDescription(criteria: string) {``
    const criterionNumber = parseInt(criteria.replace('Criteria ', '')) - 1;
    return `${criteria}: ${all_criterion[criterionNumber]}` || criteria;
}


export default (props: {firstName: string, lastName: string, letter: PetitionLetterDTO, scale: number, highlightAssetId?: string}) => {

    const {
        firstName: petitionerFirstName,
        lastName: petitionerLastName,
        letter
    } = props

    const petitionerShortName = () => {
        return "Dr. " + petitionerLastName; // The short name of the petitioner
    }

    const petitionerName = () => {
        return petitionerFirstName + ", " + petitionerLastName + " (" + petitionerShortName()  + " or Petitioner)"; // The whole name of the petitioner
    }

    const petitionCriterion = () => {
        const criteria: any = {}
        letter.content.forEach(it => criteria[it.criteria_type] = true)
        return Object.keys(criteria)
    }

    // Criterion
    // Convert petition_criterion to extract the corresponding elements from all_criterion
    const criterion = () => {
        return petitionCriterion().map(c => {
            // Extract the numeric part, subtract 1 (to make it zero-indexed), and get the element from all_criterion
            const index = parseInt(c.replace('Criteria ', '')) - 1;
            return index
        }).sort().map(index => all_criterion[index]);
    }

    // How many criterion has been satisfied
    const criteria_satisfied = () => {
        return criterion().length
    }

    // Convert petition_criterion to extract the corresponding indices
    // Will be used multiple times below.
    const highlightedIndices = () => {
        return petitionCriterion().map(c => parseInt(c.replace('Criteria ', '')) - 1);
    }

    const petitionTitle = () => {
        return "RE: I-140 Immigrant Petition - First Preference EB-1 Extraordinary Ability Petitioner: " + petitionerName()
    }

    const petitionRepresentation = () => {
        return "We represent " + petitionerShortName() + ", who is petitioning under 8 C.F.R § 204.5(h) as an alien of extraordinary ability. \
        Enclosed please find the following items in connection with the above-referenced petition:"
    }

    const groupedSections = () => {

        // Step 2: Group sections by `criteria`
        let groupedSections:{[key: string]: LetterSectionDTO[]} = {};

        letter.content.forEach(function(section) {
            if (!groupedSections[section.criteria_type]) {
                groupedSections[section.criteria_type] = [];
            }
            groupedSections[section.criteria_type].push(section);
        });

        return groupedSections
    }

    const getCustomStyle = (): CSSProperties => {
        return {
            transform: `scale(${props.scale??1})`
        }
    }

    const formatBody = (body: string) => {
        let texts: ReactNode[] = []
        let stream = ""
        let pairs = 0
        for(let token of body){
            stream += token
            if(stream.endsWith("**")){
                pairs++
            }
            if(pairs == 2){
                const splittedAt = stream.indexOf("**")
                texts.push(stream.slice(0, splittedAt))
                texts.push(<label style={{textDecoration: "underline"}}><strong>{stream.slice(splittedAt).slice(2, -2)}</strong></label>)

                stream = "";
                pairs = 0
            }
        }
        texts.push(stream)
        return texts.filter(text => text && text !== "")
    }

    useEffect(() => {

        if(props.highlightAssetId){
            window.document.querySelector(`#${props.highlightAssetId.replaceAll(":", "_")}`)?.scrollIntoView({behavior: "smooth"})
        }

    }, [props.highlightAssetId])

    const articleRender = () => {

        return (
            <article style={getCustomStyle()}>
                            {/* Note to DEV: This is the date section. */}
                            {/* <p><span id="current-date">{currentDate}</span></p> */}

                            {/* Note to DEV: This is the default Fedex tracking section. */}
                            <h3>By FedEx (Tracking No.:9999 9999 9999)</h3>
                            <p>USCIS<br/>
                                Attn: Premium I-140 (P. O. Box 21500)<br/>
                                2108 E. Elliot Rd.<br/>
                                Tempe, AZ 85284-1806
                            </p>

                            {/* Note to DEV: This is the default petition title section. */}
                            <h3 id = "petition-title">{petitionTitle()}</h3>
                            <p>Dear Sir/Madam:</p>
                            <p id = "petition-representation">{petitionRepresentation()}</p>
                            <ul>
                                <li> Form G-1450 Authorization for Credit Card Transactions of US$715 for the I-140 filing fee</li>
                                <li> Form G-1450 Authorization for Credit Card Transactions of US$600 for the Asylum Program Fee as Regular Petitioner</li>
                                <li> Form G-1450 Authorization for Credit Card Transaction of $2,805 for the Request for Premium Processing Service</li>
                                <li> Form I-907, Request for Premium Processing Service</li>
                                <li> Form G-28, “Notice of Entry of Appearance as Attorney or Representative”</li>
                                <li> Form I-140, Immigrant Petition for Alien Worker</li>
                            </ul>

                            {/* Note to DEV: This is the exhibit introduction section. */}
                            <h3>EVIDENCE PERTAINING TO BENEFICIARY'S EXTRAORDINARY ABILITY</h3>
                            <ol id="exhibit_intro_list">
                                <li><strong>EXHIBIT A:</strong>{petitionerShortName()}'s Background information</li>
                                {
                                    highlightedIndices().map((index, i) => {
                                        return (
                                            <li><strong>EXHIBIT {exhibitLetters[i]}:</strong> {all_criterion[index]}</li>
                                        )
                                    })
                                }
                            </ol>

                            {/* Note to DEV: This is the USCIS 10 criterion section. */}
                            <h3>THE DEFINITION OF “EXTRAORDINARY ABILITY” IS CONTROLLED BY STATUTE AND REGULATIONS</h3>
                            <p>Pursuant to 8 C.F.R § 204.5(h)(3), a petition for an alien of extraordinary ability must be accompanied by evidence that the alien has sustained national or international acclaim and that his or her achievements have been recognized in their field of expertise. Such evidence shall include evidence of a one-time achievement (that is, a major, internationally recognized award) or at least three of the following:</p>
                            <ol id = "uscis_criterion" className="custom-counter">
                                {
                                    all_criterion.map((item, index) => {
                                        if(highlightedIndices().includes(index)){
                                            return <li><strong>{item}</strong> </li>
                                        }else{
                                            return <li>{item}</li>
                                        }
                                    })
                                }
                            </ol>
                            <p id = "qualification_statement">
                                Comparable evidence may be submitted under 8 C.F.R §204.5(h)(4) to establish “extraordinary ability” where the above criteria do not readily apply to the beneficiary's occupation. As detailed below, {petitionerShortName()} qualifies as a person of extraordinary ability by satisfying four (4) of the enumerated extraordinary ability criteria.
                            </p>

                            {/* Note to DEV: This is the pursue work section. We don't have such data in the backend yet. */}
                            <h3 id = "pursue_work_title">
                                {petitionerShortName().toUpperCase()} SEEKS CLASSIFICATION TO PURSUE WORK IN HER FIELD OF ENDEAVOR
                            </h3>
                            <p id = "pursue_work_statement">
                                {letter.letter_us_plan}
                            </p>

                            {/* Note to DEV: This is the introduction section. We don't have such data in the backend yet. */}
                            <h3>INTRODUCTION</h3>
                            <p id = "introduction_content">{letter.letter_introduction}</p>

                            {/* Note to DEV: This is the criteria statement section. */}
                            <h3 id = "criteria_title">
                                {petitionerShortName().toUpperCase()} SATISFIES AT LEAST {numberToWord(criteria_satisfied())} ({criteria_satisfied()}) OF THE EXTRAORDINARY ABILITY CRITERIA
                            </h3>
                            <p id = "criteria_statement">
                                Dr. Li satisfies {numberToWord(criteria_satisfied())} ({criteria_satisfied()}) of the criteria listed in 8 C.F.R 204.5(h)(3) and provides extensive supporting documents:
                            </p>
                            <ol id = "criteria-list" className = "custom-counter">
                                {
                                    criterion().map((item, i) => {
                                        return (
                                            <li className={i == 3?'highlight':''}>{item}</li>
                                        )
                                    })
                                }
                            </ol>

                            {/* Note to DEV: We need to loop through all sections here */}
                            <div id="section_content">
                                {/* Sections will be inserted here */}
                                {
                                    Object.entries(groupedSections()).sort().map(([criteria, sections]) => {
                                        return (
                                            <>
                                                <h3>{getFullCriterionDescription(criteria)}</h3>
                                                {
                                                    sections.map(section => {
                                                        return (
                                                            <>
                                                                <h4>{section.section_title}</h4>
                                                                <p id={section.related_asset_id.replaceAll(":", "_")} className={(props.highlightAssetId == section.related_asset_id)?styles['highlight']:''}>{formatBody(section.body)}</p>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>

                            <h3>Appendix - Exhibit List</h3>
                            <ul>
                                {
                                    letter.exhibit_items.map(item => {
                                        return (
                                            <li>
                                                <strong>{item.label}</strong>: {item.text}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            


                        </article>
        )
    }

    const skelonRender = () => {
        return <article style={{height: "100%", boxSizing: "border-box"}}>
                <Skeleton loading={true} active   />
                <Skeleton loading={true} active   />
                <Skeleton loading={true} active   />
                <Skeleton loading={true} active   />
            </article>
    }


    return (
        <div className={styles['petition-letter']}>
            {
                letter.letter_status === "SUCCEEDED"
                ?
                articleRender()
                :
                skelonRender()
            }

        </div>
        
    )

}