
import { Col, Flex, Row, message } from "antd"
import Screen from "../components/Screen"

import logoSvg from "../login/assets/images/Logo.svg";
import useGoogleLogin from "../login/components/useGoogleLogin";
import Divider from "../login/components/Divider";
import Form from "../components/form/Form";
import { useContext, useEffect, useState } from "react";
import Link from "../login/components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import { useNavigate } from "react-router-dom";
import SignupApi from "./Signup.api";
import { RegisterParams as RegisterParams, SignupContext } from "./context/SignupContext";

import styles from "./Singup.module.scss"
import { useFormInstance } from "../components/form/FormContext";

const {
    required,
    email,
    password
} = validators


export default () => {

    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const {loginBtn} = useGoogleLogin()
    const {setRegister, register} = useContext(SignupContext)
    const formInstance = useFormInstance()

    const doSubmit = (params: any) => {
        if(loading) return;
        setLoading(true)
        messageApi.loading({content: "Registering.", duration: 0})
        // navigate("/signup/verify_email")
        params = {...register, ...params}
        return SignupApi.register(params).then((result) => {
            switch (result.code) {
                case "200":
                    switch(result.data.next_step){
                        case "verify_email":
                            navigate("/signup/verify_email")
                            break;
                        case "waitlist":
                            navigate("/addedToQueue")
                            break;
                        default:
                            throw new Error("unknown next_step: " + result.data.next_step)
                    }
                    messageApi.destroy()
                    break;
                default:
                    messageApi.destroy()
                    messageApi.error({content: result.message, duration: 3})
                    setLoading(false)
            }
            
        }).catch(() => {
            messageApi.destroy()
            messageApi.error({content: "Register failed", duration: 3})
            setLoading(false)
        })
    }

    const redirectToConsent = () => {
        window.open("/Roundblock_Brand_Partnership_Disclosure_Agreement.pdf", "_blank")
    }

    const redirectToPrivacyStatement = () => {
        window.open("/Roundblock_Privacy_Statement.pdf", "_blank")
    }

    useEffect(() => {
        formInstance.setValues("privacy_statement_acknowledged", true)
        formInstance.setValues("brand_partnership_acknowledged", true)
    }, [])

    const FormRender = (
        <Form.Form instance={formInstance} onSubmit={doSubmit}>
            <Flex vertical gap={32}>

                <Flex vertical gap={16}>

                    <Row gutter={[16, 24]}>
                        <Col span={12}>
                            <Form.Item 
                                label="First name*" 
                                name="first_name"
                                rules={[
                                    {validate:required, msg: "Enter your First name"},
                                ]}
                            >
                                <Form.Input placeholder={"First name"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item 
                                    label="Last name*" 
                                    name="last_name"
                                    rules={[
                                        {validate:required, msg: "Enter your Last name"},
                                    ]}
                                >
                                <Form.Input placeholder={"Last name"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                    label="Company Name*" 
                                    name="company_name"
                                    rules={[
                                        {validate:required, msg: "Enter your Company Name"},
                                    ]}
                                >
                                <Form.Input placeholder={"Company Name"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                    label="Message (optional)" 
                                    name="message"
                                >
                                <Form.Input placeholder={"Leave us a message..."}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Flex vertical gap={8}>
                        <Form.Item 
                            label="" 
                            name="privacy_statement_acknowledged"  
                            nowrap
                            rules={[
                                {validate:required, msg: ""},
                            ]}
                            >
                            <Form.Checkbox 
                             
                            ><a onClick={redirectToPrivacyStatement}>I acknowledged the Leazy Privacy Statement*</a></Form.Checkbox>
                        </Form.Item>
                        <Form.Item 
                            label="" 
                            name="brand_partnership_acknowledged"
                            nowrap
                            rules={[
                                {validate:required, msg: " "},
                            ]}
                            >
                            <Form.Checkbox><a onClick={redirectToConsent}>I acknowledged the Leazy Brand Partnership Disclosure Consent*</a></Form.Checkbox>
                        </Form.Item>
                        <Form.Item label="" name="consent_news_emails" nowrap>
                            <Form.Checkbox >I consent to receive emails and news from Leazy</Form.Checkbox>
                        </Form.Item>
                    </Flex>                            

                </Flex>
                
                <Form.Submit>Next</Form.Submit>
            </Flex>
        </Form.Form>
    )


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['signup']}>

                    <Flex align="center" vertical  gap={24}>
                        <Icon size={56} padding={0} src={logoSvg}></Icon>
                        <Flex vertical  gap={12}>
                            <div className={styles['signup__title']}>Tell us about you</div>
                            <div className={styles['signup__subtitle']}>We’re opening up Leazy.ai to groups of testers. In the meantime, we’d like to get to know you better</div>
                        </Flex>
                    </Flex>

                    {FormRender}
                    
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}