import axios from "axios"
import { HttpResponse } from "../libs/http"


export interface RegisterRequest{
    email: string
    password: string
  }
  
  export interface RegisterTellMeMoreRequest{
    company_name: string
    first_name: string
    last_name: string
    message: string
    signup_token: string
  }
   
  export interface RegisterDTO{
    next_step: "tell_us_more" | "verify_email" | "waitlist"
    signup_token: string
  }
  
  
  export const register = async (request: RegisterRequest | RegisterTellMeMoreRequest): Promise<HttpResponse<RegisterDTO>> => {
    const defaultParams = {
      company_name: "",
      email: "",
      first_name: "",
      last_name: "",
      message: "",
      password: "",
      signup_token: "",
    };
    const params = { ...defaultParams, ...request };
    const response = await axios.post(
      `/api/account/register`,
      params
    );
    return response.data;
  
  }

  export const resendVerifyEmail = async (email: string) => {
    const response = await axios.post(
      `/api/account/resend_verify_email`,
      { email }
    );
    return response.data;

  };

  export const verify = async (token: string) => {
    const response = await axios.post(
      `/api/account/verify`,
      { token }
    );
    return response.data;

  };


  export default {
    register,
    resendVerifyEmail,
    verify
  }