import { Flex } from "antd"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import { StepForm } from "../CaseFillingForm"
import AddLink from "../components/AddLink"
import styles from "./ReviewAndSubmit.module.scss"
import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import edit01Svg from "../assets/images/edit-01.svg"
import Icon from "../../components/icon/Icon"
import chervonDownPrimarySvg from "../assets/images/chevron-down-primary.svg"
import chervonUpPrimarySvg from "../assets/images/chevron-up-primary.svg"
import Env from "../../../Env"
import CaseFilingGraphsqlApi, { CaseFilingExtraDTO } from "../../api/casefiling/CaseFilingGraphsql.api"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import { AcademicBackgroundDTO } from "../../api/casefiling/AcademicBackgroundGraphsql.api"
import LoadingIcon from "../../components/icon/LoadingIcon"
import Button from "../../components/Button"
import { AwardDTO } from "../../api/casefiling/AwardsGraphsql.api"
import { PublicationReviewDTO } from "../../api/casefiling/ReviewGraphsql.api"
import { PatentsDTO } from "../../api/casefiling/PatentsGraphsql.api"
import { MediaCoverageDTO } from "../../api/casefiling/MediaCoveragesGraphsql.api"
import { MembershipDTO } from "../../api/casefiling/MembershipGraphsql.api"
import { ArtExhibitionDTO } from "../../api/casefiling/ArtExhibitionGraphsql.api"
import { JobDTO } from "../../api/casefiling/JobsGraphsql.api"
import CaseFillingApi from "../../api/CaseFilling.api"
import { PublicationDTO } from "../../api/casefiling/PublicationsGraphsql.api"
import useMessage from "../../components/useMessage"


export default () => {

    return (
        <StepForm
            title="Review and submit"
            subtitle="Please review the accuracy of all information provided and submit for evidence collection."
        >
            <ReviewAndSubmit/>
        </StepForm>
    )

}

const ReviewAndSubmit = () => {

    const formInstance = useFormInstance()
    const {record, update, setStep, isFinish} = useContext(CaseFillingFormContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [caseFilingExtraDTO, setCaseFilingExtraDTO] = useState<CaseFilingExtraDTO>() 
    const {render: messageRender, error} = useMessage()

    useEffect(() => {

        if(record){
            CaseFilingGraphsqlApi.getAll(record.id).then((res) => {
                setCaseFilingExtraDTO(res)
                setLoading(false)
            })
        }

    }, [])

    const doSubmit = async () => {
        if(record){
            const caseId = record.case_id
            const taskId = record.id
            try{
                await CaseFillingApi.evidence(record.case_id, record.id);
            }catch(e: any){
                if(e.code === "30001"){
                    error(e.message)
                }
                throw e
            }

            await CaseFillingApi.updateStatus(caseId, taskId, "REVIEW_SUBMIT");
            await CaseFillingApi.detail(caseId, taskId).then(update);
            setStep(CaseFilingSteps.GenerateEvidence);
        }
        
    }


    if(loading){
        return <LoadingIcon/>
    }

    

    return (
        <Flex vertical gap={48}>
            <Flex vertical gap={18}>
                <AcademicBackgroundReview data={caseFilingExtraDTO?.academicBackground}></AcademicBackgroundReview>
                <Publications data={caseFilingExtraDTO?.publications}></Publications>
                <Awards data={caseFilingExtraDTO?.awards}></Awards>
                <PublicationViews data={caseFilingExtraDTO?.reviews}></PublicationViews>
                <Patents data={caseFilingExtraDTO?.patents}></Patents>
                <MediaCoverages data={caseFilingExtraDTO?.mediaCoverages}/>
                <Memberships data={caseFilingExtraDTO?.memberships}></Memberships>
                <ArtExhibition data={caseFilingExtraDTO?.exhibitions}></ArtExhibition>
                <JobAndTitle data={caseFilingExtraDTO?.jobs}></JobAndTitle>
            </Flex>
            <Flex justify="flex-end">
                <Form.Form
                    instance={formInstance}
                    onSubmit={doSubmit}
                >
                    <Form.Item nowrap label="" name="">
                        <Form.Submit width={185} disabled={isFinish(CaseFilingSteps.ReviewAndSubmit)}>Continue</Form.Submit>
                    </Form.Item>
                </Form.Form>
                
            </Flex>
            {messageRender}
        </Flex>
    )

}



const AcademicBackgroundReview = (props: {data?: AcademicBackgroundDTO}) => {

    const {
        data = {undergraduate: [], graduate: [], highestDegree: "-"}
    } = props

    const {setStep} = useContext(CaseFillingFormContext)
 
    return (
        <ReviewPanel title= "Academic Background" onEdit={() => setStep(CaseFilingSteps.AcademicBackground)}>
            <ReviewPanelItem label="Highest academic degree" value={data.highestDegree}></ReviewPanelItem>
            <Flex vertical gap={24}>
                {
                    data.undergraduate.length > 0 &&
                    <Flex vertical gap={24}>
                        {data.undergraduate.map((it, idx) => {
                             return (
                                <ReviewPanelItemGroup title={'Undergraduate background'} hiddenTitle={idx > 0}>
                                    <ReviewPanelItem label="Undergraduate college/University" value={it.universityName}></ReviewPanelItem>
                                    <ReviewPanelItem label="Undergraduate GPA" value={it.gpa}></ReviewPanelItem>
                                    <ReviewPanelItem label="Undergraduate GPA rank" value={it.rank}></ReviewPanelItem>
                                </ReviewPanelItemGroup>
                            )
                        })}
                    </Flex>
                }

                {
                    data.graduate.length > 0 &&
                    <Flex vertical gap={24}>
                        {data.graduate.map((it, idx) => {
                             return (
                                <ReviewPanelItemGroup title={idx==0?"Graduate background":undefined}>
                                    <ReviewPanelItem label="Degree Type" value={it.degreeType}></ReviewPanelItem>
                                    <ReviewPanelItem label="Graduate college/University" value={it.universityName}></ReviewPanelItem>
                                    <ReviewPanelItem label="Graduate department/school" value={it.departmentName}></ReviewPanelItem>
                                    <ReviewPanelItem label="Graduate GPA" value={it.gpa}></ReviewPanelItem>
                                    <ReviewPanelItem label="Graduate GPA rank" value={it.rank}></ReviewPanelItem>
                                </ReviewPanelItemGroup>
                            )
                        })}
                    </Flex>
                }
                
            </Flex>
        </ReviewPanel>
    )
}

const Publications = (props: {data?: PublicationDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Publication (${data.length} items)`
        }
        return "Publication"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.Publications)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Type" value={it.type}></ReviewPanelItem>
                            <ReviewPanelItem label="Title" value={it.title}></ReviewPanelItem>
                            <ReviewPanelItem label="Author ranking" value={it.authorRanking}></ReviewPanelItem>
                            <ReviewPanelItem label="Journal/Conference name" value={it.journalConferenceName}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const Awards = (props: {data?: AwardDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Awards (${data.length} items)`
        }
        return "Awards"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.Awards)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Award name" value={it.awardName}></ReviewPanelItem>
                            <ReviewPanelItem label="Issuing authority" value={it.issueAuthority}></ReviewPanelItem>
                            <ReviewPanelItem label="Author ranking" value={it.authorRanking}></ReviewPanelItem>
                            <ReviewPanelItem label="Year" value={it.year}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const PublicationViews = (props: {data?: PublicationReviewDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Publication reviews (${data.length} items)`
        }
        return "Publication reviews"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.PublicationReviews)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Type" value={it.type}></ReviewPanelItem>
                            <ReviewPanelItem label="Journal/Conference name" value={it.journalConferenceName}></ReviewPanelItem>
                            <ReviewPanelItem label="Start year" value={it.startYear}></ReviewPanelItem>
                            <ReviewPanelItem label="End year" value={it.endYear}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const Patents = (props: {data?: PatentsDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Patent (${data.length} items)`
        }
        return "Patent"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.Patents)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Patent title" value={it.title}></ReviewPanelItem>
                            <ReviewPanelItem label="Patent number" value={it.patentNumber}></ReviewPanelItem>
                            <ReviewPanelItem label="Author ranking" value={it.authorRanking}></ReviewPanelItem>
                            <ReviewPanelItem label="Year" value={it.year}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const MediaCoverages = (props: {data?: MediaCoverageDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Media coverage (${data.length} items)`
        }
        return "Media coverage"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.MediaCoverage)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Media name" value={it.mediaName}></ReviewPanelItem>
                            <ReviewPanelItem label="Link" value={it.link}></ReviewPanelItem>
                            <ReviewPanelItem label="Year" value={it.year}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const Memberships = (props: {data?: MembershipDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Membership (${data.length} items)`
        }
        return "Membership"
    }
 
    return (
        <ReviewPanel title={getTitle()}  expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.MemberShip)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Association name" value={it.associationName}></ReviewPanelItem>
                            <ReviewPanelItem label="Position" value={it.position}></ReviewPanelItem>
                            <ReviewPanelItem label="Start year" value={it.startYear}></ReviewPanelItem>
                            <ReviewPanelItem label="End year" value={it.endYear}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const ArtExhibition = (props: {data?: ArtExhibitionDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Art exhibition/Performance (${data.length} items)`
        }
        return "Art exhibition/Performance"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.ArtExhibition)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup hiddenTitle>
                            <ReviewPanelItem label="Exhibition/Performance name" value={it.exhibitionName}></ReviewPanelItem>
                            <ReviewPanelItem label="Organizer’s name" value={it.organizerName}></ReviewPanelItem>
                            <ReviewPanelItem label="Year" value={it.year}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}

const JobAndTitle = (props: {data?: JobDTO[]}) => {

    const {
        data = []
    } = props

    const {setStep} = useContext(CaseFillingFormContext)

    const getTitle = () => {
        if(data.length > 1){
            return `Job and title (${data.length} items)`
        }
        return "Job and title"
    }
 
    return (
        <ReviewPanel title={getTitle()} expand={data.length <= 1} empty={data.length == 0} onEdit={() => setStep(CaseFilingSteps.JobAndTitle)}>
            <Flex vertical gap={24}>
                {data.map((it, idx) => {
                        return (
                        <ReviewPanelItemGroup>
                            <ReviewPanelItem label="Employer name" value={it.employerName}></ReviewPanelItem>
                            <ReviewPanelItem label="Job title" value={it.jobTitle}></ReviewPanelItem>
                            <ReviewPanelItem label="Start year" value={it.startYear}></ReviewPanelItem>
                            <ReviewPanelItem label="End year" value={it.endYear}></ReviewPanelItem>
                        </ReviewPanelItemGroup>
                    )
                })}
            </Flex>
        </ReviewPanel>
    )
}



const ReviewPanel = (props: PropsWithChildren<{title: string, expand?: boolean, empty?: boolean, onEdit?: () =>  void}>) => {

    const {
        title,
        expand,
        children,
        onEdit,
        empty
    } = props
    const [showMore, setShowMore] = useState<boolean>()
    const ref = useRef<HTMLDivElement>(null)


    const getStyles = (): CSSProperties => {
        const height = ref.current?.getBoundingClientRect().height
        Env.DEBUG &&  console.log("height: ", height)
        if(empty){
            return {
                maxHeight: "none"
            }
        }
        if(height){
            return {
                maxHeight: height
            }
        }
        
        return {}
    }

    const moreRender = () => {

        if(showMore){
            return (
                <Flex align="center" justify="center" gap={8} className={styles['review-panel__more']} onClick={() => setShowMore(false)}>
                    <div className={styles['review-panel__more__label']}>Hide all</div>
                    <Icon src={chervonDownPrimarySvg} size={16}></Icon>
                </Flex>
            )
        }else{
            return (
                <Flex align="center" justify="center" gap={8} className={styles['review-panel__more']} onClick={() => setShowMore(true)}>
                    <div className={styles['review-panel__more__label']}>Show all</div>
                    <Icon src={chervonUpPrimarySvg} size={16}></Icon>
                </Flex>
            )
        }

    }

    useEffect(() => {
        if(props.expand) setShowMore(true)
    }, [props])

    
    return (
        <div style={(showMore)?getStyles():{}} className={[styles['review-panel'], (!empty && showMore)?styles['review-panel_showmore']:''].join(" ")}>
            <Flex ref={ref} vertical gap={empty?4:23} >
                <Flex justify="space-between" align="center">
                    <div className={styles['review-panel__title']}>{title}</div>
                    <Icon src={edit01Svg} size={16} padding={4} hoverable onClick={onEdit}></Icon>
                </Flex>
                {
                    empty
                    ?
                    <div className={styles['review-panel__empty']}>The client currently has no information or details available to provide under this section.</div>
                    :
                    children}
                {
                    !expand && moreRender()
                }
            </Flex>
        </div>
    )
}

const ReviewPanelItem = (props: PropsWithChildren<{label: string, value: any}>) => {

    const {
        label,
        value
    } = props

    const textRender = () => {
        if(!value || (value as string).trim() === ""){
            return "-"
        }else{
            return value
        }
    }

    return (
        <Flex vertical className={styles['review-panel__item']}>
            <div className={styles['review-panel__item__label']}>{label}</div>
            <div className={styles['review-panel__item__value']}>{textRender()}</div>
        </Flex>
    )
}

const ReviewPanelItemGroup = (props: PropsWithChildren<{title?: string, hiddenTitle?: boolean}>) => {

    const {
        title,
        children,
        hiddenTitle
    } = props

    return (
        <Flex vertical className={styles['review-panel__itemgroup']} gap={16}>
            {!hiddenTitle && <div className={styles['review-panel__itemgroup__title']}>{title}</div>}
            <Flex vertical gap={8}>
                {children}
            </Flex>
        </Flex>
    )
}