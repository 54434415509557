import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import Env from "../../../Env";
import { CaseFilingSteps, CaseFillingFormContext } from "./CaseFillingFormContext";
import { Flex } from "antd";

export const websocketUrl = process.env.REACT_APP_WEBSOCKET_URI as string

export interface CaseFilingNotification{
    
    basicProfileExtractionSucceeded?: NotificationMessage
    cleanBasicProfileExtractionSucceeded: () => void 
    
    analyseSucceeded?: NotificationMessage
    cleanAnalyseSucceeded: () => void
    
    broadcastMessage?: BroadcastMessage
    cleanBroadcastMessage: () => void
    
    publicationExtractionSucceeded?: NotificationMessage
    cleanPublicationExtractionSucceeded: () => void 
    
    setBroadcastMessage: (message: BroadcastMessage) => void

    letterGenerateMessage?: NotificationMessage
    cleanLetterGenerateMessage: () => void

}

export type NotificationMessageEventName = 
    "basic_profile_extraction_succeeded" | 
    "publication_extraction_succeeded" |
    "publication_extraction_failed" |
    "publication_extraction_succeeded" |
    "analysis_failed" |
    "analysis_succeeded" |
    "letter_generation_succeeded" | 
    "can_update_petition_letter"
    
export interface NotificationMessage{
    user_id: string
    case_filing_task_id: string
    event_name: NotificationMessageEventName
    module: string[]
    exhibit_ids?: string[]
}

/**
 * * type=clean是特殊的信号，代表处理它的人需要删除当前的广告信息
 */
export interface BroadcastMessage{
    text?: string | BroadcastMessageTextNode
    type: "warn" | "success" | "fail" | "info" | "clean"
}

export type BroadcastMessageTextNode = () => ReactNode

export const CaseFilingNotificationContext = createContext<CaseFilingNotification>({
    cleanBasicProfileExtractionSucceeded: function (): void {
        throw new Error("Function not implemented.");
    },
    cleanAnalyseSucceeded: function (): void {
        throw new Error("Function not implemented.");
    },
    cleanBroadcastMessage: function (): void {
        throw new Error("Function not implemented.");
    },
    setBroadcastMessage: function (message: BroadcastMessage): void {
        throw new Error("Function not implemented.");
    },
    cleanPublicationExtractionSucceeded: function (): void {
        throw new Error("Function not implemented.");
    },
    cleanLetterGenerateMessage: function (): void {
        throw new Error("Function not implemented.");
    }
})

//创建websocket
Env.DEBUG && console.log("[ws]Open the Websocket: ", websocketUrl)
let websocket: any = undefined
let open:boolean = false

export const useCaseFilingNotificationContext = () => {

    const [basicProfileExtractionSucceeded, setBasicProfileExtractionSucceeded] = useState<NotificationMessage>()
    const [basicProfileExtractionFailed, setBasicProfileExtractionFailed] = useState<NotificationMessage>()
    const [publicationExtractionSucceeded, setPublicationExtractionSucceeded] = useState<NotificationMessage>()
    const [publicationExtractionFailed, setPublicationExtractionFailed] = useState<NotificationMessage>()
    const [analyseSucceeded, setAnalyseSucceeded] = useState<NotificationMessage>()
    const [analyseFailed, setAnalyseFailed] = useState<NotificationMessage>()
    const [broadcastMessage, setBroadcastMessage] = useState<BroadcastMessage>()
    const [letterGenerateMessage, setLetterGenerateMessage] = useState<NotificationMessage>()

    const initWebsocket = (url?: string) => {
        websocket = new WebSocket(url??websocketUrl)
        // Connection opened
        websocket.addEventListener("open", () => {
            Env.DEBUG && console.log("[ws]The websocket is opening")
        });
        
        // Listen for messages
        websocket.addEventListener("message", (event: any) => {
            Env.DEBUG && console.log("[ws]Receive a message: ", event)
            const message = JSON.parse(event.data) as NotificationMessage
            switch(message.event_name){
                case 'basic_profile_extraction_succeeded':
                    setBasicProfileExtractionSucceeded(message)
                    break;
                case 'analysis_succeeded':
                    setAnalyseSucceeded(message)
                    break;
                case 'publication_extraction_succeeded':
                    setPublicationExtractionSucceeded(message);
                    break;
                case 'letter_generation_succeeded':
                case 'can_update_petition_letter':
                    setLetterGenerateMessage(message);
                    break;
                default:
                    console.log("[ws-notification]收到未解析的event: ", message)//TODO 
            }

        });

        websocket.addEventListener("close", () => {
            Env.DEBUG && console.log("The websocket is closed")
            open && reconnect()
        });
    }

    const reconnect = () => {
        setTimeout(() => {
            console.log("[ws]执行断线重连")
            initWebsocket(websocketUrl)
        }, 5000);
    }

    useEffect(() => {
        open = true
        initWebsocket()
        return () => {
            try{
                open = false
                websocket.close()
            }finally{
                Env.DEBUG && console.log("Close the websocket")
            }
            
        }

    }, [])

    const cleanBasicProfileExtractionSucceeded = () => {
        setBasicProfileExtractionSucceeded(undefined)
    }

    const cleanPublicationExtractionSucceeded = () => {
        setPublicationExtractionSucceeded(undefined)
    }

    const cleanAnalyseSucceeded = () => {
        setAnalyseSucceeded(undefined)
    }

    const cleanBroadcastMessage = () =>{
        setBroadcastMessage(undefined)
    }

    const cleanLetterGenerateMessage = () =>{
        setLetterGenerateMessage(undefined)
    }

    return {
        
        basicProfileExtractionSucceeded,
        cleanBasicProfileExtractionSucceeded,

        publicationExtractionSucceeded,
        cleanPublicationExtractionSucceeded,
        
        analyseSucceeded,
        cleanAnalyseSucceeded,
        
        broadcastMessage,
        setBroadcastMessage,
        cleanBroadcastMessage,

        letterGenerateMessage,
        cleanLetterGenerateMessage
        
    }


}

export const PublicationSucceededRender = () => {

    const {setStep, record, isFinish} = useContext(CaseFillingFormContext)
    const {publicationExtractionSucceeded, cleanPublicationExtractionSucceeded, setBroadcastMessage} = useContext(CaseFilingNotificationContext)

    useEffect(() => {
        if(publicationExtractionSucceeded){
            cleanPublicationExtractionSucceeded()
            !isFinish(CaseFilingSteps.Publications) && setBroadcastMessage({
                type: "info",
                text: () => {
                    return <Flex>
                        We’ve processed your client’s publications. Please go through the&nbsp; <a onClick={() => setStep(CaseFilingSteps.Publications)} style={{textDecoration: "underline"}}>publications</a> &nbsp;and review the information.
                    </Flex>
                }
            })
        }
    }, [publicationExtractionSucceeded])

    return (
        <></>
    )
}

export const UploadResumeNotificationRender = () => {

    const {setStep} = useContext(CaseFillingFormContext)
    const {basicProfileExtractionSucceeded, setBroadcastMessage} = useContext(CaseFilingNotificationContext)

    useEffect(() => {
        if(basicProfileExtractionSucceeded){
            setBroadcastMessage({
                type: "success",
                text: "We have just helped you autofill all the information from your resume. Please review it carefully."
            })
        }
    }, [basicProfileExtractionSucceeded])

    return (
        <></>
    )
}