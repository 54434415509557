import { createContext, useState } from "react"
import { CaseFilingStatus, CaseFilingTaskDTO, InfoSource, ResumeInfoSource, SourceType } from "../../api/CaseFilling.api"
import Env from "../../../Env"


export const CaseFilingSteps = {
    UploadResume: 1,
    FieldOfApplication: 2,
    AcademicBackground: 3,
    Publications: 4,
    Awards: 5,
    PublicationReviews: 6,
    Patents: 7,
    MediaCoverage: 8,
    MemberShip: 9,
    ArtExhibition: 10,
    JobAndTitle: 11,
    ReviewAndSubmit: 12,
    GenerateEvidence: 13
}


export interface CaseFillingFormContextProps{
    step: number
    setStep: (step: number) => void
    init: (record: CaseFilingTaskDTO) => void
    update: (record: CaseFilingTaskDTO) => void
    isFinish: (step: number) => boolean
    isActivate: (step: number) => boolean
    finishStep: number
    record?: CaseFilingTaskDTO
    getInfoSource: (sourceType: SourceType) => InfoSource | undefined
    isDisable: (step: number) => boolean
    setDisable: (step: number, disable?: boolean) => void
}

export interface FormFillingState{
    finish: boolean
}

export const CaseFillingFormContext = createContext<CaseFillingFormContextProps>({
    step: 0,
    setStep: function (step: number): void {
        throw new Error("Function not implemented.")
    },
    init: function (record: CaseFilingTaskDTO): void {
        throw new Error("Function not implemented.")
    },
    isFinish: function (step: number): boolean {
        throw new Error("Function not implemented.")
    },
    isActivate: function (step: number): boolean {
        throw new Error("Function not implemented.")
    },
    finishStep: 0,
    getInfoSource: function (sourceType: SourceType): ResumeInfoSource | undefined {
        throw new Error("Function not implemented.")
    },
    update: function (record: CaseFilingTaskDTO): void {
        throw new Error("Function not implemented.")
    },
    isDisable: function (step: number): boolean {
        throw new Error("Function not implemented.")
    },
    setDisable: function (step: number, disable?: boolean): void {
        throw new Error("Function not implemented.")
    }
})

export const useCaseFillingFormContext = (): CaseFillingFormContextProps => {

    const [step, setStep] = useState<number>(0)
    const [record, setRecord] = useState<CaseFilingTaskDTO>()
    const [finishStep, setFinishStep] = useState<number>(0)
    const [disables, setDisables] = useState<(boolean|undefined)[]>([])

    const isFinish = (current: number) => {
        
        const stauts = convertToStatus(current)
        switch(current){
            case 2://field_application
                return !!(record?.field_application)
                break;
            default:
                if(stauts){
                    return record?.collect_info_status[stauts]??false
                }else{
                    return false
                }
        }
    }

    const isActivate = (current: number) => {

        switch(current){
            case 2://field_application
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                return record?.resume_extraction_status == 'SUCCEEDED'
            case 12:
                return (
                    isFinish(CaseFilingSteps.AcademicBackground) && 
                    isFinish(CaseFilingSteps.Publications) && 
                    isFinish(CaseFilingSteps.Awards) && 
                    isFinish(CaseFilingSteps.PublicationReviews) && 
                    isFinish(CaseFilingSteps.Patents) && 
                    isFinish(CaseFilingSteps.MediaCoverage) && 
                    isFinish(CaseFilingSteps.MemberShip) && 
                    isFinish(CaseFilingSteps.ArtExhibition) && 
                    isFinish(CaseFilingSteps.JobAndTitle)
                )
            default:
                return current <= (finishStep + 1)
        }

        
    }

    const init = (record: CaseFilingTaskDTO) => {
        setRecord(record)
        Env.DEBUG && console.log("初始化caseFilingTask: ", record)
        
        let _finishStep = getFinishStep(record)
        setFinishStep(_finishStep)
    }

    const update = (record: CaseFilingTaskDTO) => {
        setRecord(record)
        Env.DEBUG && console.log("更新caseFilingTask: ", record)
        
        let _finishStep = getFinishStep(record)
        setFinishStep(_finishStep)
    }

    const getInfoSource = (sourceType: SourceType) => {
        return record?.info_source?.find(it => it.source_type == sourceType)
    }

    const isDisable = (step: number) => {
        return !disables[step]
    }

    const _setDisable = (step: number, disable?: boolean) => {
        setDisables(current => {
            if(current[step] != disable){
                current[step] = disable
                return [...current]
            }else{
                return current
            }
        })
    }


    return {
        step,
        setStep,
        init,
        isFinish,
        isActivate,
        finishStep,
        record,
        getInfoSource,
        update,
        isDisable,
        setDisable: _setDisable
    }

}

export const convertToStep = (taskStatus: CaseFilingStatus) => {
    switch(taskStatus){
        case "UPLOAD_RESUME":
            return 1;
        case "FIELD_OF_APPLICATION":
            return 2;
        case "ACADEMIC_BACKGROUND":
            return 3;
        case "PUBLICATIONS":
            return 4;
        case "AWARDS":
            return 5;
        case "PUBLICATION_REVIEW":
            return 6;
        case "PATENTS":
            return 7;
        case "MEDIA_COVERAGE":
            return 8;
        case "MEMBERSHIP":
            return 9;
        case "ART_EXHIBITION":
            return 10;
        case "JOB_AND_TITLE":
            return 11;
        case "REVIEW_SUBMIT":
            return 12;
        default:
            return 0
    }
} 

export const convertToStatus = (step: number): CaseFilingStatus | undefined => {
    switch(step){
        case 1:
            return "UPLOAD_RESUME";
        case 2:
            return "FIELD_OF_APPLICATION";
        case 3:
            return "ACADEMIC_BACKGROUND";
        case 4:
            return "PUBLICATIONS";
        case 5:
            return "AWARDS";
        case 6:
            return "PUBLICATION_REVIEW";
        case 7:
            return "PATENTS";
        case 8:
            return "MEDIA_COVERAGE";
        case 9:
            return "MEMBERSHIP";
        case 10:
            return "ART_EXHIBITION";
        case 11:
            return "JOB_AND_TITLE";
        case 12:
            return "REVIEW_SUBMIT";
        default:
            return undefined
    }
} 

export const getFinishStep = (record: CaseFilingTaskDTO) =>{
    let _finishStep = 0
    for(let [key, value] of Object.entries(record.collect_info_status)){
        if(value){
            _finishStep = Math.max(_finishStep, convertToStep(key as CaseFilingStatus))
        }
    }
    return _finishStep
}

export const getActivateStep = (record: CaseFilingTaskDTO) =>{
    let _finishStep = getFinishStep(record)
    let finalStep =  _finishStep + 1
    return finalStep
}

