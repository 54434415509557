
import { Flex, message } from "antd"
import Screen from "../components/Screen"

import logoSvg from "../login/assets/images/Logo.svg";
import useGoogleLogin from "../login/components/useGoogleLogin";
import Divider from "../login/components/Divider";
import Form from "../components/form/Form";
import { useContext, useState } from "react";
import Link from "../login/components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import { useNavigate } from "react-router-dom";
import SignupApi from "./Signup.api";
import { RegisterParams as RegisterParams, SignupContext } from "./context/SignupContext";

import styles from "./Singup.module.scss"
import { useFormInstance } from "../components/form/FormContext";

const {
    required,
    email,
    password
} = validators


export default () => {

    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const {loginBtn} = useGoogleLogin()
    const {setRegister} = useContext(SignupContext)
    const formInstance = useFormInstance()

    const doSubmit = (params: any) => {
        if(loading) return;
        setLoading(true)
        messageApi.loading({content: "Registering", duration: 0})
        return SignupApi.register(params).then((result) => {
            switch (result.code) {
                case "200":
                    result.data.signup_token && ((params as RegisterParams)['signup_token'] = result.data.signup_token);
                    setRegister(params);
                    switch(result.data.next_step){
                        case "tell_us_more":
                            navigate("/signup/tell_us_more")
                            break;
                        case "verify_email":
                            navigate("/signup/verify_email")
                            break;
                        default:
                            throw new Error("unknown next_step: " + result.data.next_step)
                    }
                    messageApi.destroy()
                    break;
                default:
                    messageApi.destroy()
                    messageApi.error({content: result.message, duration: 3})
                    setLoading(false)
            }
            
        }).catch(() => {
            messageApi.destroy()
            messageApi.error({content: "Register failed", duration: 3})
            setLoading(false)
        })
    }

    const FormRender = (
        <Form.Form instance={formInstance} onSubmit={doSubmit}>
            <Flex vertical gap={16}>
                <Flex vertical gap={24}>
                    <Flex vertical gap={20}>
                        <Form.Item 
                            label="Email" 
                            name="email"
                            rules={[
                                {validate:required, msg: "Please enter your email"},
                                {validate:email, msg: "Email address must be in the format"}
                            ]}
                        >
                            <Form.Input placeholder={"Enter your email"}/>
                        </Form.Item>
                        <Form.Item 
                            label="Password" 
                            name="password"
                            rules={[
                                {validate:required, msg: "Please enter your password"},
                                {validate:password, msg: "at least 8 characters"},
                            ]}
                            tips="Must be at least 8 characters."    
                        >
                            <Form.Password placeholder={"Enter your password"}/>
                        </Form.Item>
                    </Flex>
                    <Form.Submit>Next</Form.Submit>
                </Flex>
                <Form.Item label="" name="remember_me" nowrap>
                    <Flex justify="space-between" align="center">
                        <Form.Checkbox>Remember for 30 days</Form.Checkbox>
                        <Link>Forgot password</Link>
                    </Flex>
                </Form.Item>
            </Flex>
        </Form.Form>
    )


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['signup']}>
                    <Flex align="center" vertical  gap={24}>
                        <Icon size={56} padding={0} src={logoSvg}></Icon>
                        <Flex vertical  gap={12}>
                            <div className={styles['signup__title']}>Create your account</div>
                            <div className={styles['signup__subtitle']}>Welcome! Please create your account to get started!</div>
                        </Flex>
                    </Flex>

                    <Flex vertical gap={16}>
                        {/* {loginStep==0&&Step1} */}
                        {FormRender}
                        <Divider.OR></Divider.OR>
                        {loginBtn}
                    </Flex>
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}