

import { useState } from "react";
import validators from "../components/form/FormValidators"
import { Outlet} from "react-router-dom";
import { RegisterParams, SignupContext } from "./context/SignupContext";


export default () => {


    return (
        <Outlet/>
    )


}