

import { Checkbox, Flex, Tooltip } from "antd"
import styles from "./InviteClientUploadExhibit.module.scss"
import Icon from "../../components/icon/Icon"
import tipSvg from "../assets/images/info-circle.svg"
import { ExhibitDTO, ExhibitRelations } from "../../api/PetitionLetter.api"
import Button from "../../components/Button"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import { useContext, useEffect, useState } from "react"
import FormValidators from "../../components/form/FormValidators"
import Env from "../../../Env"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"


export default (props: {
    relations: ExhibitRelations[], 
    tagSelections?: {[key: string]: boolean}, 
    exhibitSelections?: {[key: string]: boolean}
    onCancel: Function,
    onConfirm: (email: string, exhibitIds: string[]) => void
}) => {

    const {
        relations,
        tagSelections = {},
        exhibitSelections = {},
        onCancel,
        onConfirm
    } = props

    const formInstance = useFormInstance()
    const [selections, setSelections] = useState<{[key: string]: boolean}>(() => {
        const selections: any = {}
        for(let relation of relations){
            if(exhibitSelections[relation.id]){
                selections[relation.id] = true
            }
            relation.related_item_ids.forEach(assetId => {
                if(tagSelections[assetId]){
                    selections[relation.id] = true
                }
            })
        }
        return selections
    })
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const {client} = useContext(ClientCaseContext)

    const selectHandle = (exhibitId: string, checked: boolean) => {
        setSelections((current) =>{
            return {
                ...current,
                [exhibitId]: checked
            }
        })
        if(!checked){
            setSelectAll(false)
        }
    }

    const selectAllHandle = (checked: boolean) => {
        setSelectAll(checked)
        if(checked){
            const _selections: any = {}
            for(let relation of relations){
                relation.related_item_ids.forEach(assetId => {
                    _selections[relation.id] = true
                })
            }
            setSelections(_selections)
        }else{
            setSelections({})
        }
    }

    const checkDisable = () => {
        return !(Object.keys(selections).length > 0)
    }

    const submitHandle = async (form: any) => {
        const email = form['email']
        const exhibits: string[] = Object.entries(selections).filter(([key, value]) => value).map(([key, value]) => key)
        Env.DEBUG && console.log("submitHandle, email: ", email, " exhibits: ", exhibits)
        return onConfirm(email, exhibits)
    }

    useEffect(() => {

        if(client){
            formInstance.setInitial({"email": client.email_address})
        }

    }, [client])

    return (
        <Form.Form instance={formInstance} showError={false} onSubmit={(form) => submitHandle(form)}>
            <Flex className={styles['inviter-container']} align="center" justify="center">
                <div className={styles["inviter"]}>
                    <Flex className={styles['inviter__body']} gap={24} vertical>
                        <div className={styles['inviter__title']}>Invite client to upload exhibit</div>
                        <Flex className={styles['inviter__tips']} gap={12} align="flex-start">
                            <Icon src={tipSvg} size={20}></Icon>
                            We recommend consolidating all exhibits into a single form to avoid overwhelming the client with multiple links.
                        </Flex>
                        <div className={styles['inviter__exhibits-scrollview']}>
                            <div className={styles['inviter__exhibits']}>
                                <div className={styles['inviter__exhibits__header']}>
                                    <Checkbox checked={selectAll} onChange={(e) => selectAllHandle(e.target.checked)}>Exhibit name</Checkbox>
                                </div>
                                {relations.map((exhibit, idx) => {
                                    return (
                                        <Flex className={[styles['inviter__exhibits__item'], (idx == relations.length - 1)?styles['inviter__exhibits__item_last']:""].join(" ")} align="center">
                                            <Checkbox 
                                                checked={selections[exhibit.id]}
                                                onChange={(e) => {selectHandle(exhibit.id, e.target.checked)}}
                                                ><Tooltip placement="top" title={exhibit.name}><label className={styles['inviter__exhibits__item__label']}>{exhibit.name}</label></Tooltip>
                                            </Checkbox>
                                        </Flex>
                                    )
                                })}
                            </div>
                        </div>
                        <Form.Item label="Client email" name="email" required rules={[{validate: FormValidators.email, msg: ''}]}>
                            <Form.Input placeholder="Example@gmail.com" />
                        </Form.Item>
                    </Flex>
                    <Flex className={styles['inviter__footer']} justify="space-between">
                        <Button.WhiteBg style={{width: 270, padding: "10px 18px"}} onClick={() => {onCancel()}}>Cancel</Button.WhiteBg>
                        <Form.Item label="" name="" nowrap>
                            <Form.Submit style={{width: 270, padding: "10px 18px"}} disabled={checkDisable() || formInstance.disabled} >Send</Form.Submit>
                        </Form.Item>
                    </Flex>
                </div>
            </Flex>
        </Form.Form>
    )
}