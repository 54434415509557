

import { Flex } from "antd";
import styles from "./ClientProfile.module.scss";
import Icon from "../components/icon/Icon";

import editSvg from "./assets/images/edit-01.svg";
import trashSvg from "./assets/images/trash-01.svg";
import { useContext, useEffect, useState } from "react";
import Tabs from "../components/tabs/Tabs";
import QuickEvalution from "./modules/QuickEvalution";
import CheckStatus from "./modules/CheckStatus";
import { ClientCaseContext } from "./context/ClientCaseContext";
import names from "../libs/names";
import ChatScreen from "./modules/ChatScreen";
import { ChatScreenContext, useChatScreenContext } from "./context/ChatScreenContext";
import StyledText from "../components/text/StyledText";
import { toHumanDateTime } from "../libs/datetime";
import ClientApi, { ClientDTO } from "../api/Client.api";
import FormFilling from "../formfilling/FormFilling";
import gobackSvg from "./assets/images/goback.svg";
import { useMask } from "../components/mask/Mask";
import ClientProfileForm from "./ClientProfileForm";
import { BroadcastContext } from "../components/broadcast/BroadcastContext";
import { DeleteModal } from "../components/modal/Modal";
import ProtectPage from "../components/layout/ProtectPage";
import CaseFilling from "../casefilling/CaseFilling";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import Env from "../../Env";



export default (props: {onCancel: () => void, onUpdate: () => void, onDelete: () => void}) => {

    const [activateIdx, setActivateIdx] = useState<number>()
    const {client, updateClient, clientCase, setTools} = useContext(ClientCaseContext)
    const chatScreenContext = useChatScreenContext()
    const {render: maskRender, show, close} = useMask()
    const {error} = useContext(BroadcastContext)
    const loaderData = useRouteLoaderData("clientmanagement") as any
    const navigate = useNavigate()

    const moduleRender = () => {
        switch(activateIdx){
            case 0:
                return <CaseFilling/>
            case 1:
                return <FormFilling/>
            case 3:
                return (
                    <>
                        <div style={{flex: 1}}>
                            <QuickEvalution/>
                        </div>
                        <div style={{flex: 1}}>
                            <ChatScreen/>
                        </div>
                    </>
                )
            
            default:
                return (
                    <>
                        <div style={{flex: 1}}>
                            <CheckStatus/>
                        </div>
                        <div style={{flex: 1}}>
                            <ChatScreen/>
                        </div>
                    </>
                )
        }
    }

    useEffect(() => {

        if(clientCase){
            ClientApi.getCase(clientCase.case_id).then(({tools}) => {
                setTools(tools)
            })
        }

    }, [clientCase])

    useEffect(() => {
        Env.DEBUG && console.log("module changed: ", loaderData)
        const module = parseInt(loaderData['module'])
        const moduleId = parseInt(loaderData['moduleId'])
        setActivateIdx(module)
    }, [loaderData])

    const showEditClientForm = () => {
        show((
            <ClientProfileForm type="edit" onCancel={close} initial={client} onConfirm={(form) => {
                const request = {...client, ...form}
                return ClientApi.updateClient(request).then(() => {
                    close();
                    props.onUpdate();
                    //更新上下文
                    updateClient(request)
                }).catch(error)
            }}/>
        ))
    }

    const showDeleteClientModal = () => {
        show((
            <DeleteModal
                title="Are you sure you want to delete this profile?"
                content="Please be aware that this action is irreversible and once completed, the profile cannot be retrieved or restored."
                onCancel={close}
                onConfirm={props.onDelete}
            ></DeleteModal>
        ))
    }

    const doChangeTab = (tab: number) => {
        Env.DEBUG && console.log(`doChangeTab: tab: ${tab} current: ${activateIdx}`)
        if(activateIdx !== undefined && tab !== activateIdx){
            navigate(`/workbench/clientmanagement/${client?.client_id}/${clientCase?.case_id}/${tab}/0`)
        }
    }
    

    return (
        <ProtectPage className={styles["client-profile__container"]}>
            <div className={styles["client-profile__goback"]}>
                <Icon src={gobackSvg} size={20} padding={10} onClick={props.onCancel}></Icon>
            </div>
            <Flex vertical className={styles["client-profile"]}>
                <div className={styles['client-profile__header']}>
                    <Flex justify="space-between" className={styles['client-profile__info']}>
                        <Flex gap={32} align="center">
                            <div className={styles['client-profile__avatar']}>
                                {client && names.abbr(client.first_name, client.last_name)}
                            </div>
                            <Flex gap={24} align="center">
                                <div className={styles['client-profile__name']}>
                                    {client?.first_name} {client?.last_name}
                                </div>
                                <Flex gap={4}>
                                    <Icon src={editSvg} size={20} padding={8} hoverable onClick={showEditClientForm}></Icon>
                                    <Icon src={trashSvg} size={20} padding={8} hoverable onClick={showDeleteClientModal}></Icon>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex className={styles['client-profile__details']}>
                            <Flex vertical justify="center" gap={8} className={styles["client-profile__detail"]}>
                                <div className={styles['client-profile__detail__label']}>Case type</div>
                                <StyledText className={styles['client-profile__detail__value']}>{clientCase?.case_type}</StyledText>
                            </Flex>
                            <Flex vertical justify="center"  gap={8} className={styles["client-profile__detail"]}>
                                <div className={styles['client-profile__detail__label']}>Case id</div>
                                <StyledText className={styles['client-profile__detail__value']}>{clientCase?.case_id}</StyledText>
                            </Flex>
                            <Flex vertical justify="center" gap={8} className={styles["client-profile__detail"]}>
                                <div className={styles['client-profile__detail__label']}>Date joined</div>
                                <StyledText className={styles['client-profile__detail__value']}>{client && toHumanDateTime(client.date_joined)}</StyledText>
                            </Flex>
                            <Flex  vertical justify="center" gap={8} className={styles["client-profile__detail"]}>
                                <div className={styles['client-profile__detail__label']}>Email</div>
                                <StyledText className={styles['client-profile__detail__value']}>{client?.email_address}</StyledText>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex align="center" className={styles['client-profile__tabs']}>
                        <Tabs
                            items={['Case filing', 'Form filling', 'Case status', 'Quick evaluation']} 
                            onChange={doChangeTab}
                            init={activateIdx}
                        ></Tabs>
                    </Flex>
                </div>

                <ChatScreenContext.Provider value={chatScreenContext}>
                    <Flex className={styles['client-profile__body']} style={{flex: 1}}>
                        {moduleRender()}
                    </Flex>
                </ChatScreenContext.Provider>
            </Flex>
            {maskRender}
        </ProtectPage>
    )

}