
import { Flex } from "antd"
import firstUploadPng from "../assets/images/send2client_guideline.png"
import Button from "../../components/Button"
import styles from "./Send2ClientGuideline.module.scss"
import { useContext } from "react"
import { UserContext } from "../../login/context/UserContext"
import loginApi from "../../api/login.api"

export default (props: {onConfirm?: () => void, onCancel?: () => void}) => {

    const {
        onConfirm,
        onCancel
    } = props

    const {user, changeTourStatus} = useContext(UserContext)

    const gotItHandle = () => {
        loginApi.updateTourStauts("SEND_TO_CLIENT").then(() => {
            changeTourStatus("SEND_TO_CLIENT", true)
        })
        onCancel && onCancel()
    }

    const tryItNowHandle = () => {
        loginApi.updateTourStauts("SEND_TO_CLIENT").then(() => {
            changeTourStatus("SEND_TO_CLIENT", true)
        })
        onConfirm && onConfirm()
    }

    return (
        <Flex vertical className={styles['guideline']} gap={8} align="center">
            <img src={firstUploadPng} className={styles['guideline__img']}></img>
            <label className={styles['guideline__label']}>Send a Google Form <span className={styles['guideline__label_highlight']}>to the client</span> for evidence, which we collect and update in the system automatically.</label>
            <Flex style={{width: "100%"}} justify="flex-end" gap={12}>
                <Button.TransparentBg onClick={gotItHandle}>Got it</Button.TransparentBg>
                <Button.Primary onClick={tryItNowHandle}>Try it now</Button.Primary>
            </Flex>
        </Flex>
    )
}